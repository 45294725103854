import React from 'react'
import TrackingService from '../TrackingService';
import { useEffect, useState } from "react";
import {
  Widget,
  addResponseMessage,
  isWidgetOpened,
  addLinkSnippet,
  toggleMsgLoader,
  renderCustomComponent,
  setQuickButtons,
} from "react-chat-widget";
import axios from "axios";
import Cookies from "js-cookie";
import botAvatar from "../img/botAvatar.png";
import userAvatar from "../img/userAvatar.jpg";
import CryptoJS from "crypto-js";
import "react-chat-widget/lib/styles.css";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import ChatBotFeedback from "../Components/ChatBot/ChatBotFeedback";
import ChatBotCarousel from "../Components/ChatBot/ChatBotCarousel";
import ChatBotHeadImg from "../img/dot-head.png";
import ChatBotFullImg from "../img/ChannelPartner/Chatbot/Dot-BlinkEye.gif";
import ChatBotCloseImg from "../img/ChannelPartner/Chatbot/Chatbot_Close.png";
import logo from "../img/loading-icon-animated.gif";

import $ from 'jquery'; 

const DOC_PREVIEW = "docPreview";
const trackingService = new TrackingService();
const ChannelPartnerChatBot = (props) => {
    const [previewURL, setPreviewURL] = useState([{ url: "", doc_Link: "" }]);
    const [isChatBotOpened , setIsChatBotOpened] = useState(false);
    const[botDisplay, setBotDisplay] = useState(isChatBotOpened);

    const [modal, setModal] = useState({
        [DOC_PREVIEW]: false,
      });
      const button = [
        { label: "Yes", value: "true" },
        { label: "No", value: "false" },
      ];
      const [isMultipleResponse, setIsMultipleResponse] = useState(false);
      const [message, setMessage] = useState("For more click here");
      const [data_, setData_] = useState([]);
      const [isLoading, setIsLoading] = useState(true);

      useEffect(() => {
        if (Cookies.get("name") !== undefined && props.loadBot) {
          addResponseMessage(
            "Hello " +
              Cookies.get("name").split(' ').slice(0, -1).join('') +
              ", Thanks for choosing to chat with me."
          );
        }
      }, [Cookies.get("name"),props.loadBot]);

      const submitFeedback = (feedbackDetails) => {
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_CHATBOTAPI_CP}feedbacks`,
          headers: {
            "Content-Type": "application/json",
          },
          data: feedbackDetails,
        };
    
        axios(config).then(function (response) {});
        addResponseMessage("Thanks for submitting the feedback");
      };

      useEffect(() => {
        const $target_wid = $('.Custom_Widget .rcw-widget-container');
        const $target = $('.Custom_Widget');
        const $imgMapper = $('#img-mapper');
    if(isChatBotOpened === true){
      $target_wid.css({
        'bottom': '28vh'
    })
      if(window.location.pathname.includes("/search")){
      $target.css({
         'zIndex': '9999999'
    })
    $imgMapper.css({
      'zIndex': '-1'
    })
      }
      else{
        $target.css({
          'zIndex': '9999999'
     })
      }
    } else{
      $target_wid.css({
        'bottom': '3vh'
    })
      if(window.location.pathname.includes("/search")){
        $imgMapper.css({
          'zIndex': '-1'
        })
      $target.css({
        'zIndex': '-1'
    }) 
    }
    else{  
     
    $imgMapper.css({
      'zIndex': '1'
    })
    }
    }
      },[isChatBotOpened])

      const handleNewUserMessage = (newMessage) => {
        console.log(newMessage);
        console.log(`New message incoming! ${newMessage}`);
        console.log(Cookies.get("empnumber"), "chatbot", newMessage);
       
    
        trackingService.LogChatbotClick(Cookies.get("empnumber"), newMessage);
        var data = JSON.stringify({
          message: newMessage,
          employee_id: Cookies.get("empnumber"),
          persona: Cookies.get("roles"),
        });
        var config = {
          method: "post",
          url: `${process.env.REACT_APP_CHATBOTAPI_CP}deltabot`,
    
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };
        toggleMsgLoader();
        axios(config).then(function (response) {
          toggleMsgLoader();
          if (response.data) {
            let answerArray = (response.data.answer).split('\n')
            answerArray.map((item) => {
              if(item.includes("href"))
              {
                let title = item.split("href")[0].replace("<b><u><a ", "")
                let url = item.split("href")[1]
                .replace('="', "")
                .split('"')[0]
              }
            })
            if (response.data.answer.split("href").length > 1) {
              const title = response.data.answer
                .split("href")[0]
                .replace("<b><u><a ", "");
              const url = response.data.answer
                .split("href")[1]
                .replace('="', "")
                .split('"')[0];
              renderCustomComponent(ChatBotFeedback, {
                title,
                url,
                chatId: response.data.chat_id,
                answerArray : answerArray,
                handlePreview,
                submitFeedback,
              });
              
            } else {
              const title = response.data.answer;
              renderCustomComponent(ChatBotFeedback, {
                title,
                answerArray : answerArray,
                submitFeedback,
              });
            }
            if (response.data.message && response.data.other_responses)
              renderCustomComponent(ChatBotCarousel, {
                data: response.data,
                handlePreview,
              });
            setData_(response.data.other_responses);
          }
        });
      };
      const handlePreview = (url) => {
        var filepath =
          "F:\\AnPS\\Sharepointfiles\\" +
          url
            .replace("https://hpedelta.com:8082/", "")
            .replace(/ /g, "%20")
            .split("/")
            .join("\\");
        filepath = CryptoJS.enc.Utf8.parse(filepath);
        filepath = CryptoJS.enc.Base64.stringify(filepath);
        const previewURL = "https://hpedelta.com/Viewer.aspx?file=" + filepath;
        console.log(previewURL);
        setPreviewURL((prevState) => ({
          ...prevState,
          url: previewURL,
          doc_Link: url,
        }));
        handleModalOpen(DOC_PREVIEW);
      };
      const handleModalOpen = (item) => {
        console.log(Cookies.get("empnumber"), "chatbot");
        setModal((prevState) => ({ ...prevState, [item]: true }));
      };
      const handleModalClose = (item) => {
        setModal((prevState) => ({ ...prevState, [item]: false }));
        setIsLoading(true);
      };
      const logChatbot = () => {};
    
      const handleChatToggle = (status) => 
        setIsChatBotOpened(status)

      useEffect(()=>{
        const $target = $('.Custom_Widget');
        const $imgMapper = $('#img-mapper');
        console.log("isWidgetOpened, isWidgetOpened")
        if(window.location.pathname.includes("/search") && isWidgetOpened()){  
          setIsChatBotOpened(true)   
        $target.css({
           'zIndex': '9999999'
      })
      $imgMapper.css({
        'zIndex': '-1'
      })
        }
      },[])
      
      useEffect(()=>{
    if(isChatBotOpened== true){
      $(".project").show(500);
    }else{
      $(".project").hide(500);
    }
      },[isChatBotOpened])

    const handleIframeLoad = () => {
      setIsLoading(false)
    }
    

  return (
    <>
          <div className={window.location.pathname.includes("/search")== 1? "Custom_Widget_Ho": "Custom_Widget_Ho"}
      style={{marginTop: "10%", zIndex:"10"}}> 

     <Widget
        handleNewUserMessage={handleNewUserMessage}
        showTimeStamp={false}
        toggleMsgLoader={true}
        title={"HPE Knowledge Management Center Chat Support"}
        subtitle={"Hi, My name is Dot. How can I help you?"}
        profileAvatar={botAvatar}
        profileClientAvatar={userAvatar}
        launcherOpenImg={ChatBotFullImg}
        launcherCloseImg={ChatBotCloseImg}
        handleToggle={handleChatToggle}
      /> 
          </div> 

   {isChatBotOpened && 
     (<>
        <div className="Custom-rcw-widget-container-bot-img"> <div class="col"> 
            <div id="bot" onClick={() => setBotDisplay(true)}>
                <img src={ChatBotFullImg} width="100" height="179px" alt=""
                className={window.location.pathname.includes("/search")== 1? "home-rcw-open-launcher": "home-rcw-open-launcher"}/>
            </div>
            <div class={window.location.pathname.includes("/search")== 1?"project":"homeProject"}>
            </div>
         </div>
    </div> 
      </>)} 
    {/* Document Preview Modal */}
    {modal[DOC_PREVIEW] && (
      <Modal
        show={modal[DOC_PREVIEW]}
        onHide={() => handleModalClose(DOC_PREVIEW)}
        dialogClassName="preview-modal"
        onClick={logChatbot}
        animation={false}
      >
        <Modal.Header>
          <Modal.Title>Document Preview</Modal.Title>
          <button
            type="button"
            translate="no"
            onClick={() => handleModalClose(DOC_PREVIEW)}
            class="btn-close"
            data-dismiss="modal"
          >
            {/* × */}
          </button>
        </Modal.Header>
        <Modal.Body dialogClassName="preview-body">
          <div className="preview_iframe">
            <iframe frameborder="0" allowfullscreen src={previewURL.url} onLoad={handleIframeLoad}/>
            {isLoading ? 
            <div className="text-center">
              <img className="loading-img" src={logo} alt="loading"></img>
            </div>
             : null}
          </div>
        </Modal.Body>
      </Modal>
    )}
  </>
  )
}

export default ChannelPartnerChatBot
