import React from "react";
import axios from "axios";
import Cookies from "js-cookie";
import Modal from "react-bootstrap/Modal";
import URLConfig from "../URLConfig";

// import logo from "../img/subheader/knw-cap-icon.png";
import _ from "lodash";
import Accelerator from "./Accelerator";
import TrackingService from "../TrackingService";
import "../css/ChannelPartner/NewCSS/owl.carousel.min.css";
import "../css/ChannelPartner/NewCSS/owl.theme.default.min.css";
import "../css/ChannelPartner/NewCSS/animate.css";
import "../css/ChannelPartner/NewCSS/style2.css";
import "../css/ChannelPartner/NewCSS/responsive.css";
import DeltaHomeContext from "../Context/DeltaHomeContext";
import logo from "../img/loading-icon-animated.gif";
import Img25 from "../img/ChannelPartner/NewImg/25.png";
import Img28 from "../img/ChannelPartner/NewImg/28.png";
import trophyOffwhite from "../img/ChannelPartner/NewImg/trophy-offwhite.png";
import trophyblack2 from "../img/ChannelPartner/NewImg/trophy-black2.png";
import woodenvaas from "../img/ChannelPartner/NewImg/wooden-vaas.png";
import trophyWhite from "../img/ChannelPartner/NewImg/trophy-white.png";
import trophyBlack from "../img/ChannelPartner/NewImg/trophy-black.png";
import saba from "../img/ChannelPartner/NewImg/Saba.jpg";
// import others from "../img/ChannelPartner/NewImg/Others.jpg";
import dataSheet from "../img/ChannelPartner/NewImg/Data Sheet.jpg";
import quickSpecs from "../img/ChannelPartner/NewImg/Quick Specks.jpg";
import solutionServices from "../img/ChannelPartner/NewImg/Solution or Service Breifs.jpg";
import whitepaper from "../img/ChannelPartner/NewImg/Whitepapers.jpg";
import maintainanceAndService from "../img/ChannelPartner/NewImg/Maintainance And Services Guides.jpg";
import brochures from "../img/ChannelPartner/NewImg/Brochures.jpg";
import additionalPresentation from "../img/ChannelPartner/NewImg/Additional Presentation.jpg";
import faqs from "../img/ChannelPartner/NewImg/Faq.jpg";
import processDelivery from "../img/ChannelPartner/NewImg/Progress Delivery Guides.jpg";
import salesAndPersuite from "../img/ChannelPartner/NewImg/Sales and persuites Guides.jpg";
import playbook from "../img/ChannelPartner/NewImg/Playbook.jpg";
import networkPlaceIot from "../img/ChannelPartner/NewImg/Network Workspace and IOT.jpg";
import userGuides from "../img/ChannelPartner/NewImg/User Guides.jpg";
import ceramicVaasWhite from "../img/ChannelPartner/NewImg/ceramic-vaas-white.png";
import ceramicVaasMulticolor from "../img/ChannelPartner/NewImg/ceramic-vaas-multicolor.png";
import trophyWhite2 from "../img/ChannelPartner/NewImg/trophy-white2.png";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ChannelPartnerHeader from "./ChannelPartnerHeader";
import { UserContext } from "../Context/UserContext";
import InternalTrainingAndOthers from "../img/ChannelPartner/NewImg/Internal Training and others.jpg";
import EducationAndMOC from "../img/ChannelPartner/NewImg/Education and MOC.jpg";
import CustomerPresentation from "../img/ChannelPartner/NewImg/Customer Presentation.jpg";
import WorkshopPresentation from "../img/ChannelPartner/NewImg/Workshop Presentation.jpg";

import ProjectPresentation from "../img/ChannelPartner/NewImg/Project Presentation.jpg";
import PartnerPresentation from "../img/ChannelPartner/NewImg/Partner Presentation.jpg";
import EventPresentation from "../img/ChannelPartner/NewImg/Event Presentation.jpg";
import SalesPresentation from "../img/ChannelPartner/NewImg/Sales Presentation.jpg";
import Deliverables from "../img/ChannelPartner/NewImg/Deliverables.jpg";
import OtherTrainings from "../img/ChannelPartner/NewImg/Other Trainings.jpg";
import Articles from "../img/ChannelPartner/NewImg/Articles.jpg";
import HybridCloud from "../img/ChannelPartner/NewImg/Hybrid Cloud.jpg";
import InfoGraphic from "../img/ChannelPartner/NewImg/Info Graphic.jpg";
import Architecture from "../img/ChannelPartner/NewImg/Architecture.jpg";
import Marketinsights from "../img/ChannelPartner/NewImg/Market insights.jpg";
import TechnologyAndProductDocumentation from "../img/ChannelPartner/NewImg/Technology and Product Documentation.jpg";
import InstallationAndConfigurationGuide from "../img/ChannelPartner/NewImg/Installation and configuration guides.jpg";
// import TechnologyAndProductDocumentation from "../img/ChannelPartner/NewImg/Technology and Product Documentation.jpg"
import AdditionalResources from "../img/ChannelPartner/NewImg/Additional Resources.jpg";
import QuickReferenceCards from "../img/ChannelPartner/NewImg/Quick Reference Cards.jpg";
import ChannelPartnerDocumentsCarousal from "./ChannelPartnerDocumentsCarousal";
import Mask from "../img/ChannelPartner/NewImg/Mask.png";
import goldenhorse from "../img/ChannelPartner/NewImg/horseStatue1.png";
import Globe from "../img/ChannelPartner/NewImg/Globe.png";
import dogstatue from "../img/ChannelPartner/NewImg/dogStatue1.png";
import arti1 from "../img/ChannelPartner/NewImg/arti-1.png";
import arti2 from "../img/ChannelPartner/NewImg/arti-2.png";
import arti3 from "../img/ChannelPartner/NewImg/arti-3.png";
import plant1 from "../img/ChannelPartner/NewImg/plant-1.png";
import plant2 from "../img/ChannelPartner/NewImg/plant-2.png";
import pot1 from "../img/ChannelPartner/NewImg/pot-1.png";
import pot2 from "../img/ChannelPartner/NewImg/pot-2.png";
import pot3 from "../img/ChannelPartner/NewImg/pot-3.png";
import pot4 from "../img/ChannelPartner/NewImg/pot-4.png";
import pot5 from "../img/ChannelPartner/NewImg/pot-5.png";
import pot6 from "../img/ChannelPartner/NewImg/pot-6.png";
import trophy1 from "../img/ChannelPartner/NewImg/trophy-1.png";
import trophy2 from "../img/ChannelPartner/NewImg/trophy-2.png";
import trophy3 from "../img/ChannelPartner/NewImg/trophy-4.png";
import artifact1 from "../img/ChannelPartner/NewImg/artifacts-1.png";
import artifact2 from "../img/ChannelPartner/NewImg/artifacts-2.png";
import artifact3 from "../img/ChannelPartner/NewImg/artifacts-3.png";
import artifact4 from "../img/ChannelPartner/NewImg/artifacts-4.png";
import artifact5 from "../img/ChannelPartner/NewImg/pot-9.png";
import artifact6 from "../img/ChannelPartner/NewImg/pot-9a.png";
import artifact7 from "../img/ChannelPartner/NewImg/pot-9b.png";
import artifact8 from "../img/ChannelPartner/NewImg/pot-9c.png";
import artifact9 from "../img/ChannelPartner/NewImg/pot-8.png";
import artifact10 from "../img/ChannelPartner/NewImg/flwr-pot.png";
import artifact11 from "../img/ChannelPartner/NewImg/arti-9.png";
import others from "../img/ChannelPartner/NewImg/Others.jpg";
import Accelerators from "../img/ChannelPartner/NewImg/Accelerators-JPG.jpg";
import Greenlake from "../img/ChannelPartner/NewImg/Greenlake-JPG.jpg";
import referenceArchitecture from "../img/ChannelPartner/NewImg/Reference-Architecture-JPG.jpg";
import MarketInsight from "../img/ChannelPartner/NewImg/Market-Insight-Jpg.jpg";
import SuccessStory from "../img/ChannelPartner/NewImg/Success-Story-JPG.jpg";
import AnnouncementsandCommunications from "../img/ChannelPartner/NewImg/Announcement-and-Communication-JPG.jpg";
import CustomerReferencesorCaseStudy from "../img/ChannelPartner/NewImg/Customer-Reference-of-Case-Study- jpg.jpg";
import CompetitiveInsights from "../img/ChannelPartner/NewImg/Competetive-Insight-JPG.jpg";
import BestPractices from "../img/ChannelPartner/NewImg/Best-Practices-JPG.jpg";
import ProcessDocuments from "../img/ChannelPartner/NewImg/Process-Documents-JPG.jpg";
import github from "../img/ChannelPartner/NewImg/Github-JPG.jpg";
import demo from "../img/ChannelPartner/NewImg/Demo.png";

class ChannelPartnerKnowledgeCapsule extends React.Component {
  static contextType = DeltaHomeContext;

  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.isChannelPartner = localStorage.getItem("isChannelPartner");

    this.dividerImages = [
      trophyBlack,
      trophyOffwhite,
      trophyWhite,
      trophyWhite2,
      trophyblack2,
      Globe,
      Mask,
      pot1,
      pot3,
      pot4,
      pot5,
      pot6,
      trophy1,
      trophy2,
      trophy3,
      artifact1,
      artifact2,
      artifact4,
      artifact5,
      artifact6,
      artifact8,
      artifact9,
      artifact10,
      artifact11,
    ];
    this.dividerImageCache = {};
    this.usedImages = [];
    this.state = {
      results: "",
      popupHeader: "",
      moreData: null,
      activePage: 1,
      itemsCountPerPage: this.isChannelPartner === "1" ? 7 : 10,
      totalItemsCount: 0,
      filters: "",
      searchKey: "",
      showMore: false,
      highlight: "",
      isGuides: false,
      isPresentation: false,
      isServices: false,
      isMultimedia: false,
      isReuse: false,
      isInternalTraining: false,
      isOtherServices: false,
      isMultimedia: false,
      subcategoryhighlight: "",
      checkCategory: [],
      resultsHTML: "",
      accelerator: false,
      documentName: "",
      documentDetailsAll: [],
      searchMoreKey: "",
    };
  }

  handleSearchValueChange = (event) => {
    const searchValue = event.target.value;
    this.setState({ searchKey: searchValue });
  };
  surveyClose = () => {
    const { isClose } = this.props;
    isClose(false);
  };
  resetResults = (categoryName) => {
    this.setState({ searchKey: "" }, () => this.handlePageChange(1,categoryName));
  };

  resetResultsDocumentsData = () => {
    this.setState({ searchKey: "" }, () => this.handlePageChangeDocumentsData(1));
  };
  handleState = () => {
    // setAccelerator(false);
    this.setState({ accelerator: false });
  };

  fireSearch = (event,categoryName) => {
    if (event.keyCode === 13) {
      // Enter key
      this.handlePageChange(1,categoryName);
    }
  };

  
  fireSearchDocumentsData = (event) => {
    if (event.keyCode === 13) {
      // Enter key
      this.handlePageChangeDocumentsData(1);
    }
  };
  componentDidMount = () => {
    this.setState({ checkCategory: this.props.checkSelectedCategory });
    this.resizeAllCapsules();
    const filters = this.props.filters !== null ? this.props.filters : "";
    this.setState({ filters });
    this.findrelevancy();
    console.log(" componentDidMount channelPartnerSearch");
    this.ImageIndex = 0;
  };

  findrelevancy = () => {
    var checkCategory = this.props.checkSelectedCategory;
    let arr = [];
    if (checkCategory !== undefined) {
      if (this.state.checkCategory != undefined) {
        var i;
        for (i = 0; i < checkCategory.length; i++) {
          if (checkCategory[i] == "Guides") {
            arr = [...arr, this.props.relevancyScore.guidesMaxScore];
          }
          if (checkCategory[i] == "Presentations") {
            arr = [...arr, this.props.relevancyScore.presentationsMaxScore];
          }
          if (checkCategory[i] == "Multimedia") {
            arr = [...arr, this.props.relevancyScore.multimediaMaxScore];
          }
          if (checkCategory[i] == "Training") {
            arr = [...arr, this.props.relevancyScore.trainingsMaxScore];
          }
          if (checkCategory[i] == "Other Services") {
            arr = [...arr, this.props.relevancyScore.otherservicesMaxScore];
          }
          if (checkCategory[i] == "Reuse") {
            arr = [...arr, this.props.relevancyScore.reuseMaxScore];
          }
          if (checkCategory[i] == "Service") {
            arr = [...arr, this.props.relevancyScore.servicesMaxScore];
          }
          // Commented on 11 jan 2024 blocking AcceleratorCategory
          // if (checkCategory[i] == "Accelerators") {
          //   arr = [...arr, this.props.relevancyScore.acceleratorMaxScore]
          // }
        }
        let result = _.maxBy(arr, "score");
        this.setState({ highlight: result?.item }, () =>
          this.highlightCategory(this.state.highlight)
        );
      }
    } else {
      arr = [
        this.props.relevancyScore.guidesMaxScore,
        this.props.relevancyScore.presentationsMaxScore,
        this.props.relevancyScore.servicesMaxScore,
        this.props.relevancyScore.trainingsMaxScore,
        this.props.relevancyScore.otherservicesMaxScore,
        this.props.relevancyScore.reuseMaxScore,
        this.props.relevancyScore.multimediaMaxScore,
        this.props.relevancyScore.acceleratorMaxScore,
      ];
      let result = _.maxBy(arr, "score");
      this.setState({ highlight: result?.item }, () =>
        this.highlightCategory(this.state.highlight)
      );
    }
  };

  componentDidUpdate = (prevProps) => {
    this.resizeAllCapsules();
    setTimeout(() => {
      this.resizeAllCapsules();
    }, 3000);
    if (!_.isEqual(prevProps.relevancyScore, this.props.relevancyScore)) {
      this.findrelevancy();
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (
      props.filters !== undefined &&
      props.filters.length !== state.filters.length
    ) {
      return { filters: props.filters };
    }
    return null;
  }

  highlightCategory = (categoryname) => {
    switch (categoryname) {
      case "Guides":
        this.setState({
          isGuides: true,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isReuse: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.guidesMaxScore.subitem,
        });
        break;
      case "Presentation":
        this.setState({
          isPresentation: true,
          isServices: false,
          isMultimedia: false,
          isReuse: false,
          isGuides: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.presentationsMaxScore.subitem,
        });
        break;
      case "Services":
        this.setState({
          isServices: true,
          isMultimedia: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.servicesMaxScore.subitem,
        });
        break;
      case "MultiMedia":
        this.setState({
          isMultimedia: true,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.multimediaMaxScore.subitem,
        });
        break;
      case "Reuse":
        this.setState({
          isReuse: true,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isOtherServices: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight: this.props.relevancyScore.reuseMaxScore.subitem,
        });
        break;
      case "Other Services":
        this.setState({
          isOtherServices: true,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.otherservicesMaxScore.subitem,
        });
        break;
      case "Internal Trainings":
        this.setState({
          isOtherServices: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: true,
          isAccelerator: false,
          subcategoryhighlight:
            this.props.relevancyScore.trainingsMaxScore.subitem,
        });
        break;
      // Commented on 11 jan 2024 as blocking AcceleratorCategory component
      // case "Accelerators":
      //   this.setState({
      //     isOtherServices: false,
      //     isReuse: false,
      //     isGuides: false,
      //     isPresentation: false,
      //     isServices: false,
      //     isMultimedia: false,
      //     isInternalTraining: false,
      //     isAccelerator:true,
      //     subcategoryhighlight:
      //       this.props.relevancyScore.acceleratorMaxScore.subitem,
      //   })
      //   break;
      default:
        this.setState({
          isOtherServices: false,
          isReuse: false,
          isGuides: false,
          isPresentation: false,
          isServices: false,
          isMultimedia: false,
          isInternalTraining: false,
          isAccelerator: false,
          subcategoryhighlight: "",
        });
    }
  };

  resizeAllCapsules() {
    const ele = document.getElementsByClassName("capsule-container");
    if (ele.length > 2) {
      for (var i = 0; i < ele.length; i++) {
        ele[i].classList.remove("col-6");
        ele[i].classList.add("col-4");
      }
    } else if (ele.length == 1) {
      ele[0].classList.remove("col-6");
      ele[0].classList.remove("col-4");
      ele[0].classList.add("col-12");
    }
    if (ele.length == 2) {
      for (var i = 0; i < ele.length; i++) {
        ele[i].classList.remove("col-4");
        ele[i].classList.remove("col-12");
        ele[i].classList.add("col-6");
      }
    }
  }

  logDocClick = (documentDetails) => {
    console.log("logDocClick");
    this.setState({ documentDetailsAll: documentDetails });
    if (!this.TrackingService) {
      this.TrackingService = new TrackingService();
    }
    this.TrackingService.OpenLink(Cookies.get("empnumber"), documentDetails);
  };
  viewDocument = (document) => {
    this.setState({ accelerator: true, documentName: document });
    const URL =
      URLConfig.getURLDeltaAPI() + "GreenlakeDocument/" + document + ".html";
    axios.get(URL).then((res) => {
      if (res.data) {
        this.setState({ resultsHTML: res.data });
      }
    });
  };

  formatDate = (date) => {
    if (date === "" || date === undefined) {
      return "";
    }
    var dt = date.split("T");
    return dt[0];
  };


  fetchMore = (
    
    pageNumber = this.state.activePage,
    categoryName,
    searchMoreKey = this.state.searchKey
  ) => {
    const begin =
      pageNumber * this.state.itemsCountPerPage - this.state.itemsCountPerPage;
    const url =
      URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?fl=file,url,rating,disclosure_level,isgoldcollateral,title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22Guides%22%20AND%20document_type_details:%22" +
      URLConfig.getSolrBaseURL()+"/select?fl=file,url,rating,"+URLConfig.isDisclosureLevel()+"isgoldcollateral,title,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22"+categoryName+"%22%20AND%20document_type_details:%22" +
      this.state.popupHeader +
      "%22&indent=on&q=" +
      this.context.ChannelPartnerSearchTerm +
      "&rows=10&start=" +
      begin +
      "&wt=json" +
      (this.state.filters.indexOf('&fq=nda:"True"') === -1
        // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
        ? URLConfig.isNDA(true) + URLConfig.GetUserRoles()
        : "") +
      this.state.filters +
      (searchMoreKey ? `&fq=file:%22${searchMoreKey}%22` : "");
    // axios.get(url)
    URLConfig.getnewsolrBaseURL(url)
    .then((res) => {
      if (res.data.response.docs) {
        this.setState({
          moreData: res.data.response,
          InitialData: res.data.response,
          totalItemsCount: res.data.response.numFound,
        });
      }
    });
    this.setState({ activePage: pageNumber });
    if(this.componentRef.current !==null) { 
    this.componentRef.current.scrollTop = { top: 0, behavior: "smooth" };
    }
  };

  handlePageChange = (pageNumber,categoryName)=> {
    console.log("handlePageChange", pageNumber);
    this.fetchMore(pageNumber,categoryName);
    this.setState({ activePage: pageNumber });
    if (this.componentRef.current !== null) {
      this.componentRef.current.scrollTop = 0;
    }
  };


  handlePageChangeDocumentsData = (pageNumber) => {
    console.log("handlePageChange", pageNumber);
    this.fetchMoreDocumentsData(pageNumber);
    this.setState({ activePage: pageNumber });
    if (this.componentRef.current !== null) {
      this.componentRef.current.scrollTop = 0;
    }
  };

  fetchMoreDocumentsData = (
    pageNumber = this.state.activePage,
    searchKey = this.state.searchKey
  ) => {
    const begin =
      pageNumber * this.state.itemsCountPerPage - this.state.itemsCountPerPage;
    const searchWord = document
      .getElementsByClassName("react-autosuggest__input")[0]
      .value.replace(/[#?&@]/g, " ")
      .replace(/-/g, "%20");
    const url =
      URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?fl=id,file%2curl%2crating%2cdisclosure_level%2cdoc_sourcee%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
      URLConfig.getSolrBaseURL() +
      "/select?fl=id,file%2curl%2crating%2c" +
      URLConfig.isDisclosureLevel(true) +
      "doc_sourcee%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
      this.state.popupHeader +
      "%22&indent=on&q=" +
      searchWord +
      "&rows=10&start=" +
      begin +
      "&wt=json" +
      URLConfig.GetUserRoles() +
      this.state.filters +
      (searchKey ? `&fq=file:%22${searchKey}%22` : "");
    // axios.get(url)
    URLConfig.getnewsolrBaseURL(url)
    .then((res) => {
      if (res.data.response.docs) {
        this.setState({
          moreData: res.data.response,
          InitialData: res.data.response,
          totalItemsCount: res.data.response.numFound,
        });
      }
    });
    this.setState({ activePage: pageNumber });
    if (this.componentRef.current !== null) {
      this.componentRef.current.scrollTop = 0;
    }
  };

  ShowMoreDocumentsData = (categoryName, subCategoryName) => {
    // Service Capsule

    // console.log("ShowMore", docs);
    // // const groupValue = docs.groupValue;
    // this.setState({
    //   popupHeader: groupValue,
    //   moreData: docs.doclist,
    //   activePage: 1,
    // });
    const searchWord = document
      .getElementsByClassName("react-autosuggest__input")[0]
      .value.replace(/[#?&@]/g, " ")
      .replace(/-/g, "%20");
    const url =
      URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?fl=id,file%2curl%2crating%2cdisclosure_level%2cdoc_source%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22Service Capsule%22 AND document_type:%22" +
      URLConfig.getSolrBaseURL() +
      "/select?fl=id,file%2curl%2crating%2c" +
      URLConfig.isDisclosureLevel(true) +
      "doc_source%2csysmodtimestamp%2cmodified_date,file_type,document_type,isarchived&fq=scope:%22" +
      categoryName +
      "%22 AND document_type:%22" +
      subCategoryName +
      "%22&indent=on&q=" +
      searchWord +
      "&rows=10&start=0&wt=json" +
      URLConfig.GetUserRoles() +
      this.state.filters;
    // axios.get(url)
    URLConfig.getnewsolrBaseURL(url)
    .then((res) => {
      if (res.data.response.docs) {
        console.log("res", res);
        this.setState({
          popupHeader: subCategoryName,
          moreData: res.data.response,
          InitialData: res.data.response,
          activePage: 1,
          totalItemsCount: res.data.response.numFound,
          showMore: true,
          searchKey: "",
        });
      }
    });
  };

  ShowMore = (categoryName, subcategoryName) => {
    console.log("ShowMore - GuidesData");
    const URLGuides =
      URLConfig.get_APIHost() +
      // "solr/sharepoint_index/select?fl=file,url,rating,disclosure_level,title,isgoldcollateral,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22Guides%22%20AND%20document_type_details:%22" +
      URLConfig.getSolrBaseURL() +
      "/select?fl=file,url,rating," +
      URLConfig.isDisclosureLevel() +
      "title,isgoldcollateral,id,doc_source,sysmodtimestamp,modified_date,file_type,isarchived&fq=document_type:%22" +
      categoryName +
      "%22%20AND%20document_type_details:%22" +
      subcategoryName +
      "%22&indent=on&q=" +
      this.context.ChannelPartnerSearchTerm +
      "&rows=10&start=0&wt=json" +
      (this.state.filters.indexOf('&fq=nda:"True"') === -1
        ? // ? "&fq=-nda:%22True%22" + URLConfig.GetUserRoles()
          URLConfig.isNDA(true) + URLConfig.GetUserRoles()
        : "") +
      this.state.filters;
    // axios.get(URLGuides)
    URLConfig.getnewsolrBaseURL(URLGuides)
    .then((res) => {
      if (res.data.response) {
        this.setState({
          moreData: res.data.response,
          popupHeader: subcategoryName,
          activePage: 1,
          totalItemsCount: res.data.response.numFound,
          showMore: true,
          searchKey: "",
        });
      }
      console.log(
        "showMore",
        this.state.showMore,
        this.state.GuideList,
        res.data.response
      );
    });
  };

  handleCloseMore = () => {
    this.setState({ showMore: false });
  };
  getImage = (subcategoryName) => {
    // const images = [
    //   EducationAndMOC,
    //   ProjectPresentation,
    //   Deliverables,
    //   Articles,
    //   Architecture,
    //   Marketinsights,
    //   AdditionalResources,
    //   QuickReferenceCards,
    //   others,
    //   solutionServices,
    // ];

    if (subcategoryName === "Greenlake") {
      return Greenlake;
    } else if (subcategoryName === "Accelerators") {
      return Accelerators;
    } else if (subcategoryName === "Reference Architectures") {
      return referenceArchitecture;
    } else if (subcategoryName === "Market Insights") {
      return MarketInsight;
    } else if (subcategoryName === "Success Story") {
      return SuccessStory;
    } else if (subcategoryName === "Announcements and Communications") {
      return AnnouncementsandCommunications;
    } else if (subcategoryName === "Customer References or Case Study") {
      return CustomerReferencesorCaseStudy;
    } else if (subcategoryName === "Competitive Insights") {
      return CompetitiveInsights;
    } else if (subcategoryName === "Best Practices") {
      return BestPractices;
    } else if (subcategoryName === "Process Documents") {
      return ProcessDocuments;
    } else if (subcategoryName === "Github") {
      return github;
    } else if (subcategoryName == "Brochures") {
      return brochures;
    } else if (subcategoryName == "Solution or Service Briefs") {
      return solutionServices;
    } else if (subcategoryName == "Whitepapers") {
      return whitepaper;
    } else if (subcategoryName == "Datasheets") {
      return dataSheet;
    } else if (subcategoryName == "User Guides") {
      return userGuides;
    } else if (subcategoryName == "QuickSpecs") {
      return quickSpecs;
    } else if (subcategoryName == "Installation and Configuration Guides") {
      return InstallationAndConfigurationGuide;
    } else if (subcategoryName == "FAQ") {
      return faqs;
    } else if (subcategoryName == "Playbook") {
      return playbook;
    } else if (subcategoryName == "Customer Presentations") {
      return CustomerPresentation;
    } else if (subcategoryName == "Sales Presentations") {
      return SalesPresentation;
    } else if (subcategoryName == "Workshop Presentations") {
      return WorkshopPresentation;
    } else if (subcategoryName == "Partner Presentations") {
      return PartnerPresentation;
    } else if (subcategoryName == "Additional Presentations") {
      return additionalPresentation;
    } else if (subcategoryName == "FAQ") {
      return faqs;
    } else if (subcategoryName == "Hybrid Cloud") {
      return HybridCloud;
    } else if (subcategoryName == "Network Workplace and IOT") {
      return networkPlaceIot;
    } else if (subcategoryName == "Saba") {
      return saba;
    } else if (subcategoryName == "Other ") {
      return OtherTrainings;
    } else if (subcategoryName == "Event Presentations") {
      return EventPresentation;
    } else if (subcategoryName == "Process Delivery Guide") {
      return processDelivery;
    } else if (subcategoryName == "Sales and Pursuit Guides") {
      return salesAndPersuite;
    } else if (subcategoryName == "Maintenance and Service Guides") {
      return maintainanceAndService;
    } else if (subcategoryName == "Technology and Product Documentation") {
      return TechnologyAndProductDocumentation;
    } else if (subcategoryName == "Infographic") {
      return InfoGraphic;
    }else if (subcategoryName == "Demo") {
      return demo;
    }else {
      return others;
      // const currentIndex = this.ImageIndex;
      // this.ImageIndex++;
      // if (this.ImageIndex === images.length) {
      //   this.ImageIndex = 0;
      // }
      // // const randomIndex = Math.floor(Math.random()*(images.length-1))

      // return images[currentIndex];
    }
  };

  getDividerImage = (imageId) => {
    const imageIndex = Math.ceil(
      Math.random() * (this.dividerImages.length - 1)
    );
    this.dividerImageCache[imageId] = this.dividerImages[imageIndex];
    this.usedImages = [...this.usedImages, this.dividerImages[imageIndex]];
    this.dividerImages = [
      ...this.dividerImages.filter((image) => {
        return image !== this.dividerImages[imageIndex];
      }),
    ];
    if (this.dividerImages.length === 0) {
      this.dividerImages = [...this.usedImages];
      this.usedImages = [];
    }
    console.log(
      "usedImages dividerImages",
      this.usedImages,
      this.dividerImages,
      this.dividerImageCache
    );
    return this.dividerImageCache[imageId];
  };
  render() {
    const documentsList = this.props.docs;
    let hpseList =
      this.props.hpse !== null ? this.props.hpse.slice(0, 30) : null;

    if (
      (this.props.selectedFilters &&
        this.props.selectedFilters.SelectedGoldCollateral) ||
      (this.props.selectedFilters &&
        this.props.selectedFilters.IsNDAOnly === true)
    ) {
      hpseList = null;
    }
    const skuList =
      this.props.SKUData !== null ? this.props.SKUData.slice(0, 1000) : null;
    // const GuidesData =
    //   this.props.GuidesData != null &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Guides"
    //   )
    //     ? this.props.GuidesData
    //     : null;

    const GuidesData =
      this.props.GuidesData != null ? this.props.GuidesData : null;

    console.log(
      "this.props.GuidesData",
      this.props.GuidesData,
      GuidesData,
      this.props.SelectedKnowledgeCategory
    );
    // const PresentationsData =
    //   this.props.Presentations != null &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Presentations"
    //   )
    //     ? this.props.Presentations
    //     : null;

    const PresentationsData =
      this.props.Presentations != null ? this.props.Presentations : null;
    // const ServicesData =
    //   this.props.ServicesData != null &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Services"
    //   )
    //     ? this.props.ServicesData
    //     : null;
    const ServicesData =
      this.props.ServicesData != null ? this.props.ServicesData : null;
    // const MultimediaData =
    //   (this.props.MultimediaData !== null ||
    //     this.props.MultimediaData !== undefined) &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Multimedia"
    //   )
    //     ? this.props.MultimediaData
    //     : null;
    const MultimediaData =
      this.props.MultimediaData !== null ||
      this.props.MultimediaData !== undefined
        ? this.props.MultimediaData
        : null;

    // const POCList =
    //   this.props.POCList !== null &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some((e) => e.categoryName === "POC")
    //     ? this.props.POCList
    //     : null;

    const POCList = this.props.POCList !== null ? this.props.POCList : null;

    // const TrainingsData =
    //   this.props.TrainingsData != null &&
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Internal Trainings"
    //   )
    //     ? this.props.TrainingsData
    //     : null;

    const TrainingsData =
      this.props.TrainingsData != null ? this.props.TrainingsData : null;
    // const AcceleratorData =
    //   this.props.SelectedKnowledgeCategory !== undefined &&
    //   this.props.SelectedKnowledgeCategory.some(
    //     (e) => e.categoryName === "Accelerators"
    //   )
    //     ? this.props.acceleratorData
    //     : null;

    const AcceleratorData = this.props.acceleratorData
      ? this.props.acceleratorData
      : null;

    console.log(AcceleratorData);
    const filters = this.props.filters !== null ? this.props.filters : null;
    const filterData = this.props.filterData?.length > 0 ? true : false;

    // const isChannelPartner = localStorage.getItem("isChannelPartner");
    const greenLakeObject = documentsList?.find((list) => {
      return list.groupValue === "Greenlake";
    });

    const acceleratorsObject = documentsList?.find((list) => {
      return list.groupValue === "Accelerators";
    });

    const githubObject = documentsList?.find((list) => {
      return list.groupValue === "Github";
    });
    const referenceArchitecturesObject = documentsList?.find((list) => {
      return list.groupValue === "Reference Architectures";
    });

    const marketInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Market Insights";
    });

    const successStoryObject = documentsList?.find((list) => {
      return list.groupValue === "Success Story";
    });

    const announcementsAndCommunicationsObject = documentsList?.find((list) => {
      return list.groupValue === "Announcements and Communications";
    });

    const customerReferencesOrCaseStudyObject = documentsList?.find((list) => {
      return list.groupValue === "Customer References or Case Study";
    });

    const competitiveInsightsObject = documentsList?.find((list) => {
      return list.groupValue === "Competitive Insights";
    });

    const bestPracticesObject = documentsList?.find((list) => {
      return list.groupValue === "Best Practices";
    });

    const processDocumentsObject = documentsList?.find((list) => {
      return list.groupValue === "Process Documents";
    });
    const demoObject = documentsList?.find((list) => {
      return list.groupValue === "Demo";
    });


    const documentData = [];
    if (
      greenLakeObject !== undefined &&
      greenLakeObject?.doclist.docs.length !== 0
    ) {
      documentData.push(greenLakeObject);
    }

    if (
      acceleratorsObject !== undefined &&
      acceleratorsObject?.doclist.docs.length !== 0
    ) {
      documentData.push(acceleratorsObject);
    }

    if (githubObject !== undefined && githubObject?.doclist.docs.length !== 0) {
      documentData.push(githubObject);
    }

    if (
      referenceArchitecturesObject !== undefined &&
      referenceArchitecturesObject?.doclist.docs.length !== 0
    ) {
      documentData.push(referenceArchitecturesObject);
    }

    if (
      marketInsightsObject !== undefined &&
      marketInsightsObject?.doclist.docs.length !== 0
    ) {
      documentData.push(marketInsightsObject);
    }

    if (
      successStoryObject !== undefined &&
      successStoryObject?.doclist.docs.length !== 0
    ) {
      documentData.push(successStoryObject);
    }
    if (
      announcementsAndCommunicationsObject !== undefined &&
      announcementsAndCommunicationsObject?.doclist.docs.length !== 0
    ) {
      documentData.push(announcementsAndCommunicationsObject);
    }

    if (
      customerReferencesOrCaseStudyObject !== undefined &&
      customerReferencesOrCaseStudyObject?.doclist.docs.length !== 0
    ) {
      documentData.push(customerReferencesOrCaseStudyObject);
    }
    if (
      competitiveInsightsObject !== undefined &&
      competitiveInsightsObject?.doclist.docs.length !== 0
    ) {
      documentData.push(competitiveInsightsObject);
    }

    if (
      bestPracticesObject !== undefined &&
      bestPracticesObject?.doclist.docs.length !== 0
    ) {
      documentData.push(bestPracticesObject);
    }

    if (
      processDocumentsObject !== undefined &&
      processDocumentsObject?.doclist.docs.length !== 0
    ) {
      documentData.push(processDocumentsObject);
    }

    if (
      demoObject !== undefined &&
      demoObject?.doclist.docs.length !== 0
    ) {
      documentData.push(demoObject);
    }
    let noOfCategories = 0;
    const getSpaceRequiredForEachCategory = () => {
      if (
        PresentationsData !== undefined &&
        PresentationsData !== null &&
        PresentationsData.groups !== undefined &&
        PresentationsData.groups.length !== 0
      ) {
        noOfCategories++;
      }
      if (
        MultimediaData !== undefined &&
        MultimediaData !== null &&
        MultimediaData.groups !== undefined &&
        MultimediaData.groups.length !== 0
      ) {
        noOfCategories++;
      }

      if (
        TrainingsData !== undefined &&
        TrainingsData !== null &&
        TrainingsData.groups !== undefined &&
        TrainingsData.groups.length !== 0
      ) {
        noOfCategories++;
      }

      if (
        GuidesData !== undefined &&
        GuidesData !== null &&
        GuidesData.groups !== undefined &&
        GuidesData.groups.length !== 0
      ) {
        noOfCategories++;
      }

      if (
        ServicesData !== undefined &&
        ServicesData !== null &&
        ServicesData.groups !== undefined &&
        ServicesData.groups.length !== 0
      ) {
        noOfCategories++;
      }

      if (POCList !== undefined && POCList !== null && POCList.length !== 0) {
        noOfCategories++;
      }

      if (documentData.length > 0) {
        noOfCategories++;
      }

      const noOfCategoriesPerRow = Math.ceil(noOfCategories / 3);

      const spaceRequiredForEachCategory = Math.ceil(6 / noOfCategoriesPerRow);

      return spaceRequiredForEachCategory;
    };

    let startfrom = 1;
    let remSpace = 6;
    let getSpaceRequiredForEachCategoryVar = getSpaceRequiredForEachCategory();

    const updateRemSpace = (categoryName) => {
      let spaceRequired =
        categoryName === "" ? 1 : getSpaceRequiredForEachCategoryVar;
      // if (spaceRequired === 3) {
      //   spaceRequired = 2;
      // }
      let noOfBooks = 2;
      if (categoryName === "Presentation") {
        console.log("PresentationsData", PresentationsData.groups.length);
        if (PresentationsData.groups.length === 1) {
          noOfBooks = 1;
        } else if (PresentationsData.groups.length === 2) {
          console.log("PresentationsData if", PresentationsData.groups.length);
          // spaceRequired = getSpaceRequiredForEachCategory();
          noOfBooks = 2;
        } else {
          // spaceRequired = 2;
          noOfBooks = 5;
        }
      } else if (categoryName === "Guide") {
        if (GuidesData.groups.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else if (GuidesData.groups.length == 2) {
          // spaceRequired = 1;
          noOfBooks = 2;
        } else {
          // spaceRequired = 2;
          noOfBooks = 4;
        }
      } else if (categoryName === "Multimedia") {
        if (MultimediaData.groups.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else {
          // spaceRequired = 2;
          noOfBooks = 2;
        }
      } else if (categoryName === "Internal Training") {
        if (TrainingsData.groups.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else {
          // spaceRequired = 2;
          noOfBooks = 2;
        }
      } else if (categoryName === "Services") {
        if (ServicesData.groups.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else {
          // spaceRequired = 2;
          noOfBooks = 2;
        }
      } else if (categoryName === "Poc Central") {
        if (POCList.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else {
          // spaceRequired = 2;
          noOfBooks = 2;
        }
      } else if (categoryName === "Others") {
        if (documentData.length == 1) {
          // spaceRequired = 1;
          noOfBooks = 1;
        } else {
          // spaceRequired = 2;
          noOfBooks = 2;
        }
      }
      if (spaceRequired === 1) {
        noOfBooks = 1;
      }

      // noOfBooks = 2;
      if (remSpace < spaceRequired) {
        startfrom = 1;
        remSpace = 6;
      }
      remSpace = remSpace - spaceRequired;
      return { spaceRequired: spaceRequired, noOfBooks: noOfBooks };
    };

    const updateStartfrom = (spaceRequired) => {
      startfrom = (startfrom + spaceRequired) % 6;
      if (startfrom === 0) {
        startfrom = 6;
      }
      // if(startfrom === 1){
      //   startfrom=2
      // }
    };
    const renderDividers = (categoryName, noOfBooks, isFront) => {
      if (getSpaceRequiredForEachCategoryVar === 3) {
        console.log(
          "renderDividers",
          getSpaceRequiredForEachCategoryVar,
          this.dividerImageCache
        );
        if (noOfBooks <= 2) {
          console.log(
            "renderDividers if",
            getSpaceRequiredForEachCategoryVar,
            this.dividerImageCache
          );
          return (
            <div className="d-flex flex-row seprater-intermediate-wrapper justify-content-around">
              <div className="seprater-intermediate">
                <img
                  src={
                    this.dividerImageCache[categoryName + "_1"]
                      ? this.dividerImageCache[categoryName + "_1"]
                      : this.getDividerImage(categoryName + "_1")
                  }
                  alt=""
                />
              </div>
              <div className="seprater-intermediate">
                <img
                  src={
                    this.dividerImageCache[categoryName + "_2"]
                      ? this.dividerImageCache[categoryName + "_2"]
                      : this.getDividerImage(categoryName + "_2")
                  }
                  alt=""
                />
              </div>
            </div>
          );
        } else {
          return (
            <div
              className="d-flex flex-row seprater-intermediate-wrapper justify-content-around"
              style={{ left: "6.9vw" }}
            >
              <div className="seprater-intermediate">
                <img
                  src={
                    this.dividerImageCache[categoryName + "_1"]
                      ? this.dividerImageCache[categoryName + "_1"]
                      : this.getDividerImage(categoryName + "_1")
                  }
                  alt=""
                />
              </div>
            </div>
          );
        }
      } else if (getSpaceRequiredForEachCategoryVar === 2) {
        if (noOfBooks <= 2) {
          console.log("renderDividers if", getSpaceRequiredForEachCategoryVar);
          return (
            <div className="d-flex flex-row seprater-intermediate-wrapper justify-content-around">
              <div className="seprater-intermediate">
                <img
                  src={
                    this.dividerImageCache[categoryName + "_1"]
                      ? this.dividerImageCache[categoryName + "_1"]
                      : this.getDividerImage(categoryName + "_1")
                  }
                  alt=""
                />
              </div>
            </div>
          );
        }
      } else if (getSpaceRequiredForEachCategoryVar === 6) {
        // if (noOfBooks <= 2) {
        //   console.log("renderDividers if", getSpaceRequiredForEachCategoryVar);
        return (
          <div
            className={
              "d-flex flex-row seprater-intermediate-wrapper justify-content-around " +
              (isFront
                ? " seprater-intermediate-wrapper-front "
                : " seprater-intermediate-wrapper-end ")
            }
          >
            <div className="seprater-intermediate">
              <img
                src={
                  this.dividerImageCache[
                    categoryName + "_1" + (isFront ? "_Front" : "_End")
                  ]
                    ? this.dividerImageCache[
                        categoryName + "_1" + (isFront ? "_Front" : "_End")
                      ]
                    : this.getDividerImage(
                        categoryName + "_1" + (isFront ? "_Front" : "_End")
                      )
                }
                alt=""
              />
            </div>
            <div className="seprater-intermediate">
              <img
                src={
                  this.dividerImageCache[
                    categoryName + "_2" + (isFront ? "_Front" : "_End")
                  ]
                    ? this.dividerImageCache[
                        categoryName + "_2" + (isFront ? "_Front" : "_End")
                      ]
                    : this.getDividerImage(
                        categoryName + "_2" + (isFront ? "_Front" : "_End")
                      )
                }
                alt=""
              />
            </div>
          </div>
        );
        // } else {
        //   return (
        //     <div
        //       className="d-flex flex-row seprater-intermediate-wrapper justify-content-around"
        //       style={{ left: "6.9vw" }}
        //     >
        //       <div className="seprater-intermediate">
        //         <img src={ceramicVaasMulticolor} alt="" />
        //       </div>
        //     </div>
        //   );
        // }
      }
    };

    const categoryData = [];
    const genericCategoryProcessing = () => {
      if (
        !(
          PresentationsData === undefined ||
          PresentationsData === null ||
          PresentationsData.groups === undefined ||
          PresentationsData.groups.length === 0
        )
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Presentation");
        categoryData.push({
          name: "Presentation",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            PresentationsData === undefined ||
            PresentationsData === null ||
            PresentationsData.groups === undefined ||
            PresentationsData.groups.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  <p class="d-text">PRESENTATION</p>
                </div>
                <div className="selfCarousal  d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Presentation", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Presentation_1_standalone"]
                            ? this.dividerImageCache[
                                "Presentation_1_standalone"
                              ]
                            : this.getDividerImage("Presentation_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                    <ChannelPartnerDocumentsCarousal
                       // docList={list.doclist.docs}
                       documentData={PresentationsData.groups}
                       size={4}
                       logDocClick={this.logDocClick}
                       viewDocument={this.viewDocument}
                       // showPreview={list.groupValue === "Demo" ? false : true}
                       // groupValue={list.groupValue}
                       // showCarousal={true}
                       noOfBooks={noOfBooks}
                       getImage={this.getImage}
                       showMoreDocData={this.ShowMore}
                       show={this.state.showMore}
                       handleClose={this.handleCloseMore}
                       searchKey={this.state.searchKey}
                       handleSearchValueChange={this.handleSearchValueChange}
                       fireSearch={this.fireSearch}
                       // logDocClick={this.logDocClick}
                       activePage={this.state.activePage}
                       totalItemsCount={this.state.totalItemsCount}
                       handlePageChange={this.handlePageChange}
                       resetResults={this.resetResults}
                       itemsCountPerPage={this.state.itemsCountPerPage}
                       moreData={this.state.moreData}
                       categoryName="Presentations"
                       popupHeader={this.state.popupHeader}
                     />

                    {/* <Presentations
                      data={PresentationsData}
                      filters={filters}
                      isPresentation={this.state.isPresentation}
                      subcategoryhighlight={this.state.subcategoryhighlight}
                      noOfBooks={noOfBooks}
                      getImage={this.getImage}
                    /> */}
                  </div>
                  {renderDividers("Presentation", noOfBooks)}
                  {/* <div class="seprater">
                      <img src={trophyOffwhite} alt="" />
                    </div> */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (
        !(
          MultimediaData === undefined ||
          MultimediaData === null ||
          MultimediaData.groups === undefined ||
          MultimediaData.groups.length === 0
        )
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Multimedia");
        categoryData.push({
          name: "Multimedia",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            MultimediaData === undefined ||
            MultimediaData === null ||
            MultimediaData.groups === undefined ||
            MultimediaData.groups.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  <p class="d-text">MULTIMEDIA</p>
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Multimedia", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Multimedia_1_standalone"]
                            ? this.dividerImageCache["Multimedia_1_standalone"]
                            : this.getDividerImage("Multimedia_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                    {MultimediaData !== undefined && (
                       <ChannelPartnerDocumentsCarousal
                       // docList={list.doclist.docs}
                       documentData={MultimediaData.groups}
                       size={4}
                       logDocClick={this.logDocClick}
                       viewDocument={this.viewDocument}
                       // showPreview={list.groupValue === "Demo" ? false : true}
                       // groupValue={list.groupValue}
                       // showCarousal={true}
                       noOfBooks={noOfBooks}
                       getImage={this.getImage}
                       showMoreDocData={this.ShowMore}
                       show={this.state.showMore}
                       handleClose={this.handleCloseMore}
                       searchKey={this.state.searchKey}
                       handleSearchValueChange={this.handleSearchValueChange}
                       fireSearch={this.fireSearch}
                       // logDocClick={this.logDocClick}
                       activePage={this.state.activePage}
                       totalItemsCount={this.state.totalItemsCount}
                       handlePageChange={this.handlePageChange}
                       resetResults={this.resetResults}
                       itemsCountPerPage={this.state.itemsCountPerPage}
                       moreData={this.state.moreData}
                       categoryName="Multimedia"
                       popupHeader={this.state.popupHeader}
                     />
                     
                    )}
                  </div>
                  {renderDividers("Multimedia", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                    <div class="seprater">
                      <img src={ceramicVaasMulticolor} alt="" />
                    </div>
                  )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (
        !(
          TrainingsData === undefined ||
          TrainingsData === null ||
          TrainingsData.groups === undefined ||
          TrainingsData.groups.length === 0
        )
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Internal Training");
        categoryData.push({
          name: "Internal Training",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            TrainingsData === undefined ||
            TrainingsData === null ||
            TrainingsData.groups === undefined ||
            TrainingsData.groups.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  {/* <OverlayTrigger
                  trigger={["hover", "focus"]}
           placement="top"
                  overlay={
                    <Tooltip style={{ border: "0px" }}>
                      INTERNAL TRAININGS
                    </Tooltip>
                  }
                > */}
                  <p class="d-text">INTERNAL TRAININGS</p>
                  {/* </OverlayTrigger> */}
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Internal Training", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache[
                            "Internal Training_1_standalone"
                          ]
                            ? this.dividerImageCache[
                                "Internal Training_1_standalone"
                              ]
                            : this.getDividerImage(
                                "Internal Training_1_standalone"
                              )
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                   <ChannelPartnerDocumentsCarousal
                       // docList={list.doclist.docs}
                       documentData={TrainingsData.groups}
                       size={4}
                       logDocClick={this.logDocClick}
                       viewDocument={this.viewDocument}
                       // showPreview={list.groupValue === "Demo" ? false : true}
                       // groupValue={list.groupValue}
                       // showCarousal={true}
                       noOfBooks={noOfBooks}
                       getImage={this.getImage}
                       showMoreDocData={this.ShowMore}
                       show={this.state.showMore}
                       handleClose={this.handleCloseMore}
                       searchKey={this.state.searchKey}
                       handleSearchValueChange={this.handleSearchValueChange}
                       fireSearch={this.fireSearch}
                       // logDocClick={this.logDocClick}
                       activePage={this.state.activePage}
                       totalItemsCount={this.state.totalItemsCount}
                       handlePageChange={this.handlePageChange}
                       resetResults={this.resetResults}
                       itemsCountPerPage={this.state.itemsCountPerPage}
                       moreData={this.state.moreData}
                       categoryName="Internal Trainings"
                       popupHeader={this.state.popupHeader}
                     />
                  </div>
                  {renderDividers("Internal Training", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                    <div class="seprater">
                      <img src={trophyWhite} alt="" />
                    </div>
                  )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (
        !(
          GuidesData === undefined ||
          GuidesData === null ||
          GuidesData.groups === undefined ||
          GuidesData.groups.length === 0
        )
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Guide");
        categoryData.push({
          name: "Guide",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            GuidesData === undefined ||
            GuidesData === null ||
            GuidesData.groups === undefined ||
            GuidesData.groups.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                  // style={{ marginLeft: noOfBooks === 4 ? "0px" : "0px" }}
                >
                  <p class="d-text">GUIDES</p>
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Guide", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Guide_1_standalone"]
                            ? this.dividerImageCache["Guide_1_standalone"]
                            : this.getDividerImage("Guide_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                    <ChannelPartnerDocumentsCarousal
                      // docList={list.doclist.docs}
                      documentData={GuidesData.groups}
                      size={4}
                      logDocClick={this.logDocClick}
                      viewDocument={this.viewDocument}
                      // showPreview={list.groupValue === "Demo" ? false : true}
                      // groupValue={list.groupValue}
                      // showCarousal={true}
                      noOfBooks={noOfBooks}
                      getImage={this.getImage}
                      showMoreDocData={this.ShowMore}
                      show={this.state.showMore}
                      handleClose={this.handleCloseMore}
                      searchKey={this.state.searchKey}
                      handleSearchValueChange={this.handleSearchValueChange}
                      fireSearch={this.fireSearch}
                      // logDocClick={this.logDocClick}
                      activePage={this.state.activePage}
                      totalItemsCount={this.state.totalItemsCount}
                      handlePageChange={this.handlePageChange}
                      resetResults={this.resetResults}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      moreData={this.state.moreData}
                      categoryName="Guides"
                      popupHeader={this.state.popupHeader}
                    />
                  </div>
                  {renderDividers("Guide", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                    <div class="seprater">
                      <img src={trophyOffwhite} alt="" />
                    </div>
                  )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (
        !(
          ServicesData === undefined ||
          ServicesData === null ||
          ServicesData.groups === undefined ||
          ServicesData.groups.length === 0
        )
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Services");
        categoryData.push({
          name: "Services",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            ServicesData === undefined ||
            ServicesData === null ||
            ServicesData.groups === undefined ||
            ServicesData.groups.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  <p class="d-text">SERVICES</p>
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Services", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Services_1_standalone"]
                            ? this.dividerImageCache["Services_1_standalone"]
                            : this.getDividerImage("Services_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                   <ChannelPartnerDocumentsCarousal
                       // docList={list.doclist.docs}
                       documentData={ServicesData.groups}
                       size={4}
                       logDocClick={this.logDocClick}
                       viewDocument={this.viewDocument}
                       // showPreview={list.groupValue === "Demo" ? false : true}
                       // groupValue={list.groupValue}
                       // showCarousal={true}
                       noOfBooks={noOfBooks}
                       getImage={this.getImage}
                       showMoreDocData={this.ShowMore}
                       show={this.state.showMore}
                       handleClose={this.handleCloseMore}
                       searchKey={this.state.searchKey}
                       handleSearchValueChange={this.handleSearchValueChange}
                       fireSearch={this.fireSearch}
                       // logDocClick={this.logDocClick}
                       activePage={this.state.activePage}
                       totalItemsCount={this.state.totalItemsCount}
                       handlePageChange={this.handlePageChange}
                       resetResults={this.resetResults}
                       itemsCountPerPage={this.state.itemsCountPerPage}
                       moreData={this.state.moreData}
                       categoryName="Services"
                       popupHeader={this.state.popupHeader}
                     />
                  </div>
                  {renderDividers("Services", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                  <div class="seprater">
                    <img src={trophyOffwhite} alt="" />
                  </div>
                   )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (
        !(POCList === undefined || POCList === null || POCList.length === 0)
      ) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Poc Central");
        categoryData.push({
          name: "Poc Central",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            POCList === undefined ||
            POCList === null ||
            POCList.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  <p class="d-text">POC CENTRAL</p>
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Poc Central", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Poc Central_1_standalone"]
                            ? this.dividerImageCache["Poc Central_1_standalone"]
                            : this.getDividerImage("Poc Central_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                    {POCList ? (
                      <ChannelPartnerDocumentsCarousal
                      // docList={list.doclist.docs}
                      documentData={POCList}
                      size={4}
                      logDocClick={this.logDocClick}
                      viewDocument={this.viewDocument}
                      // showPreview={list.groupValue === "Demo" ? false : true}
                      // groupValue={list.groupValue}
                      // showCarousal={true}
                      noOfBooks={noOfBooks}
                      getImage={this.getImage}
                      showMoreDocData={this.ShowMore}
                      show={this.state.showMore}
                      handleClose={this.handleCloseMore}
                      searchKey={this.state.searchKey}
                      handleSearchValueChange={this.handleSearchValueChange}
                      fireSearch={this.fireSearch}
                      // logDocClick={this.logDocClick}
                      activePage={this.state.activePage}
                      totalItemsCount={this.state.totalItemsCount}
                      handlePageChange={this.handlePageChange}
                      resetResults={this.resetResults}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      moreData={this.state.moreData}
                      categoryName="POC Central"
                      popupHeader={this.state.popupHeader}
                    />
                    ) : null}
                  </div>
                  {renderDividers("Poc Central", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                    <div class="seprater">
                      <img src={ceramicVaasMulticolor} alt="" />
                    </div>
                  )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      if (!(documentData.length === 0)) {
        let { spaceRequired, noOfBooks } = updateRemSpace("Others");
        categoryData.push({
          name: "Others",
          Noofvalues: 2,
          gridColumn: startfrom + "/span " + spaceRequired,
          content:
            documentData.length === 0 ? null : (
              <div>
                <div
                  class={
                    "name-plate " +
                    (getSpaceRequiredForEachCategoryVar === 6
                      ? " name-plate-space-required-6 "
                      : "")
                  }
                >
                  <p class="d-text">OTHERS</p>
                </div>
                <div className="selfCarousal d-flex flex-row">
                  {getSpaceRequiredForEachCategoryVar === 6 ? (
                    renderDividers("Others", noOfBooks, true)
                  ) : (
                    <div class="seprater seprater-front">
                      <img
                        src={
                          this.dividerImageCache["Others_1_standalone"]
                            ? this.dividerImageCache["Others_1_standalone"]
                            : this.getDividerImage("Others_1_standalone")
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div
                    className={
                      "carousal-column" +
                      (getSpaceRequiredForEachCategoryVar === 6
                        ? " carousal-column-space-required-6 "
                        : " ")
                    }
                  >
                    <ChannelPartnerDocumentsCarousal
                      // docList={list.doclist.docs}
                      documentData={documentData}
                      size={4}
                      logDocClick={this.logDocClick}
                      viewDocument={this.viewDocument}
                      // showPreview={list.groupValue === "Demo" ? false : true}
                      // groupValue={list.groupValue}
                      // showCarousal={true}
                      noOfBooks={noOfBooks}
                      getImage={this.getImage}
                      showMoreDocData={this.ShowMoreDocumentsData}
                      show={this.state.showMore}
                      handleClose={this.handleCloseMore}
                      searchKey={this.state.searchKey}
                      handleSearchValueChange={this.handleSearchValueChange}
                      fireSearch={this.fireSearchDocumentsData}
                      // logDocClick={this.logDocClick}
                      activePage={this.state.activePage}
                      totalItemsCount={this.state.totalItemsCount}
                      handlePageChange={this.handlePageChangeDocumentsData}
                      resetResults={this.resetResultsDocumentsData}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      moreData={this.state.moreData}
                      categoryName="Service Capsule"
                      popupHeader={this.state.popupHeader}
                    />
                  </div>
                  {renderDividers("Others", noOfBooks)}
                  {/* {startfrom + spaceRequired === 6 + 1 ? null : (
                    <div class="seprater">
                      <img src={ceramicVaasWhite} alt="" />
                    </div>
                  )} */}
                </div>
              </div>
            ),
        });
        updateStartfrom(spaceRequired);
      }

      const fillEmptySpaces = () => {
        console.log(
          "categoryData.length*getSpaceRequiredForEachCategoryVar",
          categoryData.length,
          getSpaceRequiredForEachCategoryVar
        );

        for (
          let i = 1;
          i <= 18 - noOfCategories * getSpaceRequiredForEachCategoryVar;
          i++
        ) {
          console.log(
            "for loop",
            18 - noOfCategories * getSpaceRequiredForEachCategoryVar
          );
          dividerProcessing(startfrom, "column_" + i);
        }
      };
      fillEmptySpaces();
    };

    const dividerProcessing = (dividerStartFrom, imageID) => {
      updateRemSpace("");
      categoryData.push({
        name: "",
        Noofvalues: 2,
        gridColumn: dividerStartFrom + "/span " + 1,
        content: (
          <div>
            <div className="selfCarousal  d-flex flex-row">
              <div class="seprater seprater-column">
                <img
                  src={
                    this.dividerImageCache[imageID]
                      ? this.dividerImageCache[imageID]
                      : this.getDividerImage(imageID)
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        ),
      });
      updateStartfrom(1);
    };
    genericCategoryProcessing();

    // fillEmptySpaces()
    return (
      <>
        <div>
          <main class="main">
            <div>
              <UserContext.Consumer>
                {([user, dispatch]) => {
                  return (
                    <>
                      <ChannelPartnerHeader
                        onSubmit={this.props.onSubmit}
                        user={user}
                        dispatch={dispatch}
                      ></ChannelPartnerHeader>
                    </>
                  );
                }}
              </UserContext.Consumer>
              <div class="bookself">
                {this.context.showLoader ? (
                  <div
                    className="text-center"
                    style={{
                      position: "absolute",
                      left: "40vw",
                      bottom: "-60vh",
                    }}
                  >
                    <img className="loading-img" src={logo} alt="loading"></img>
                  </div>
                ) : (
                  <>
                    <div
                      class="self "
                      style={{ gridColumn: categoryData[0]?.gridColumn }}
                    >
                      {console.log(
                        "find",
                        documentsList?.find((list) => {
                          return list.groupValue === "Greenlake";
                        })
                      )}
                      {categoryData[0]?.content}
                    </div>

                    <div
                      class="self"
                      style={{ gridColumn: categoryData[1]?.gridColumn }}
                    >
                      {console.log("PresentationsData", PresentationsData)}
                      {categoryData[1]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[2]?.gridColumn }}
                    >
                      {categoryData[2]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[3]?.gridColumn }}
                    >
                      {categoryData[3]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[4]?.gridColumn }}
                    >
                      {categoryData[4]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[5]?.gridColumn }}
                    >
                      {categoryData[5]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[6]?.gridColumn }}
                    >
                      {categoryData[6]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[7]?.gridColumn }}
                    >
                      {categoryData[7]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[8]?.gridColumn }}
                    >
                      {categoryData[8]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[9]?.gridColumn }}
                    >
                      {categoryData[9]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[10]?.gridColumn }}
                    >
                      {categoryData[10]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[11]?.gridColumn }}
                    >
                      {categoryData[11]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[12]?.gridColumn }}
                    >
                      {categoryData[12]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[13]?.gridColumn }}
                    >
                      {categoryData[13]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[14]?.gridColumn }}
                    >
                      {categoryData[14]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[15]?.gridColumn }}
                    >
                      {categoryData[15]?.content}
                    </div>
                    <div
                      class="self"
                      style={{ gridColumn: categoryData[16]?.gridColumn }}
                    >
                      {categoryData[16]?.content}
                    </div>
                    <div
                      class="self "
                      style={{ gridColumn: categoryData[17]?.gridColumn }}
                    >
                      {categoryData[17]?.content}
                    </div>
                    <div class="self colums19">
                      <div class="name-plate">
                        <img
                          src={InternalTrainingAndOthers}
                          style={{ visibility: "hidden" }}
                          width="65"
                          alt=""
                        />
                      </div>
                    </div>
                    <div class="self colums20">
                      <div class="name-plate">
                        <img
                          src={Img25}
                          style={{ visibility: "hidden" }}
                          width="65"
                          alt=""
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
          <footer style={{ zIndex: "0" }}>
            <div>
              <table width="100%" border="0">
                <tbody>
                  <tr>
                    <td
                      height="35"
                      style={{ textAlign: "center" }}
                      colspan="4"
                      align="-8px 0px"
                      bgcolor="#fffff"
                    >
                      © Copyright 2024 Hewlett Packard Enterprise Pvt. Ltd.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </footer>
        </div>

        <Accelerator
          results={this.state.resultsHTML}
          change={this.handleState}
          showAccelerator={this.state.accelerator}
          isAccelerator={true}
          documentName={this.state.documentName}
          docList={AcceleratorData?.docs}
          documentDetails={this.state.documentDetailsAll}
        />
      </>
    );
  }
}
export default ChannelPartnerKnowledgeCapsule;
