import ImageMapper from "react-img-mapper";
// import BgImg from "../img/NewHome/DeltaHomeNew.png";
// import ChannelPartners from "../img/ChannelPartner/NewImg/cp-bg-latest.webp"
import ChannelPartners from "../img/ChannelPartner/NewImg/CP-final.webp"


import { useEffect, useState } from "react";

const ChannelPartnersImageMapper = ({
  showFeedback,
  showDocDepo,
  showHelpVideos,
  handleShowBooks,
  carouselPositionChanged,
  updateImageMapperLoaded,
}) => {
  const [windowSizing, setWindowSizing] = useState({
    height: window.innerHeight - 60,
    width: window.innerWidth,
  });
  console.log("window.innerHeight", windowSizing);

  useEffect(() => {
    console.log("windowSizing", windowSizing);
  }, [windowSizing]);
  const [areaCoords, setAreaCords] = useState([
    {
      id: "469f9800-c45a-483f-b13e-bd24f3fb79f4",
      title: "Feedback",
      shape: "poly",
      name: "feedback",
      // fillColor: "#eab54d4d",
      // strokeColor: "black",
      fillColor: "transparent",
      strokeColor: "transparent",
      // coords: [2003,833,2054,818,2258,854,2268,1044,2207,1072,1998,1029],
      xCoords: [2003, 2054, 2258, 2268, 2207, 1998],
      yCoords: [833, 818, 854, 1044, 1072, 1029],
    },
    // {
    //   "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7ab7",
    //   "title": "ContributeKnowledge",
    //   "shape": "poly",
    //   "name": "contributeknowledge",
    //   "fillColor": "transparent",
    //   "strokeColor": "transparent",
    //   "coords": [
    //     502,644, 655,644, 673,670, 894,675, 922,713, 935,1031, 896,1092, 510,1092
    //   ],
    //   "xCoords" : [502,655,673,894,922,935, 896,510],
    //   "yCoords" : [644, 644, 670, 675,713,1031, 1092, 1092 ]
    // },
    // {
    //   "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7789",
    //   "title": "Sol360",
    //   "shape": "poly",
    //   "name": "sol360",
    //   "fillColor": "transparent",
    //   "strokeColor": "transparent",
    //   "coords": [
    //     1057,614, 1113,596, 1118,720, 1169,720, 1169,919, 1209,924, 1204,1076, 985,1079, 985,960, 1016,921, 1013,731, 1067,715
    //   ],
    //   xCoords : [1057, 1113 , 1118 , 1169, 1169, 1209 , 1204, 985, 985, 1016, 1013, 1067],
    //   yCoords : [614, 596, 720, 720, 919, 924, 1076, 1079, 960, 921, 731, 715]
    // },
    // {
    //   id: "53c311f7-4e1c-4636-ac7e-b9cdec0d7749",
    //   title: "HelpVideos",
    //   shape: "poly",
    //   name: "helpvideos",
    //   // "fillColor": "#eab54d4d",
    //   // "strokeColor": "black",
    //   fillColor: "transparent",
    //   strokeColor: "transparent",
    //   coords: [
    //     356, 1110,
    //     480, 1100,
    //     486, 1200,
    //     353, 1215
    //   ],
    //   xCoords: [
    //     356, 480, 486, 353
    //   ],
    //   yCoords: [
    //     1110, 1100, 1200, 1215
    //   ],
    // },
    {
      id: "53c311f7-4e1c-4636-ac7e-b9cdec0d7759",
      title: "DeltaBook",
      shape: "poly",
      name: "channelpartnerbook",
      //   "fillColor": "#eab54d4d",
      // "strokeColor": "black",
      fillColor: "transparent",
      strokeColor: "transparent",
      // coords: [227,673,502,668,530,935,248,950],
      xCoords: [227, 502, 530, 248],
      yCoords: [673, 668, 935, 950],
    },
    // {
    //   id: "53c311f7-4e1c-4636-ac7e-b9cdec0d7239",
    //   title: "SOWBook",
    //   shape: "poly",
    //   name: "sol360book2",
    //   //   "fillColor": "#eab54d4d",
    //   // "strokeColor": "black",
    //   fillColor: "transparent",
    //   strokeColor: "transparent",
    //   coords: [2309, 438,
    //     2476, 438,
    //     2476, 630,
    //     2309, 630],
    //   xCoords: [2309, 2476, 2476, 2309],
    //   yCoords: [438, 438, 630, 630],
    // },
    // {
    //   id: "53c311f7-4e1c-4636-ac7e-b9cdec0d7232",
    //   title: "MindSparksBook",
    //   shape: "poly",
    //   name: "mindsparksbook3",
    //   fillColor: "transparent",
    //   strokeColor: "transparent",
    //   //   "fillColor": "#eab54d4d",
    //   // "strokeColor": "black",
    //   coords: [2030, 602,
    //     2195, 602,
    //     2195, 787,
    //     2030, 787],
    //   xCoords: [2030, 2195, 2195, 2030],
    //   yCoords: [602, 602, 787, 787],
    // },
    // {
    //   "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7230",
    //   "title": "A&PSBook",
    //   "shape": "poly",
    //   "name": "a&psbook",
    //   "fillColor": "transparent",
    //   "strokeColor": "transparent",
    //   "coords": [
    //     2156,513,2331,513,2334,714,2156,719
    //   ],
    //   xCoords : [2156,2331, 2334, 2156],
    //   yCoords : [513, 513, 714, 719]
    // },
    // {
    //   "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7231",
    //   "title": "AccBook",
    //   "shape": "poly",
    //   "name": "accbook",
    //   "fillColor": "transparent",
    //   "strokeColor": "transparent",
    //   "coords": [
    //     2362,510,2542,508,2548,714,2364,719
    //   ],
    //   xCoords : [2362, 2542,2548,2364],
    //   yCoords: [510,508,714,719]
    // },
    // {
    //   "id": "53c311f7-4e1c-4636-ac7e-b9cdec0d7235",
    //   "title": "ChatBotBook",
    //   "shape": "poly",
    //   "name": "chatbotbook",
    //   "fillColor": "transparent",
    //   "strokeColor": "transparent",
    //   "coords": [
    //     2573,517,2746,512,2751,720,2573,720
    //   ],
    //   xCoords : [2573,2746,2751,2573],
    //   yCoords : [517,512,720,720]
    // },
    // {
    //   id: "469f9800-c45a-483f-b13e-bd24f3fb29f4",
    //   title: "NewsCarousel",
    //   shape: "poly",
    //   name: "newscarousel",
      // fillColor: "transparent",
      // strokeColor: "transparent",
      // "fillColor": "#eab54d4d",
      // "strokeColor": "black",
      // coords: [1019,593,1655,578,1685,982,1062,1041],
    //   xCoords: [1019, 1655, 1685, 1062],
    //   yCoords: [593, 578, 982, 1041],
    // },
  ]);

  // width - 410 (1725 - 1315) 2 - 0
  // height - 240 (955 - 715) 5 - 1

  const autoResize = () => {
    setWindowSizing({
      height: window.innerHeight - 10,
      width: window.innerWidth,
    });
    const ratio = window.innerWidth / 2880;
    const heightRatio = window.innerHeight / 1396;

    let existingCoords = [...areaCoords];

    let finalCords = [];
    for (const area of existingCoords) {
      const newCoords = [];
      const newXCoords = [];
      const newYCoords = [];

      for (const i in area.xCoords) {
        newXCoords.push(Math.round(area.xCoords[i] * ratio));
        newYCoords.push(Math.round(area.yCoords[i] * heightRatio));
        newCoords.push(Math.round(area.xCoords[i] * ratio));
        newCoords.push(Math.round(area.yCoords[i] * heightRatio));
      }

      // for (const originalCoord of area.coords) {
      //     newCoords.push(Math.round(originalCoord * ratio));
      // }
      // finalCords.push({
      //   ...area,
      //   coords : area.name === "feedback" ? [72 , 314,   143, 306,  164, 320,  167, 389,   94, 402,   73, 385]  : newCoords
      // })

      finalCords.push({
        ...area,
        yCoords: newYCoords,
        coords: newCoords,
        xCoords: newXCoords,
      });

      if (area.name === "newscarousel") {
        let carouselCoords = {
          left: newCoords[0].toString() + "px",
          top: (newCoords[1] + 60).toString() + "px",
          height: (newCoords[5] - newCoords[1]).toString() + "px",
          width: (newCoords[2] - newCoords[0]).toString() + "px",
        };
        carouselPositionChanged(carouselCoords);
      }
    }

    setAreaCords(finalCords);
    console.log("finalCords", finalCords);
  };

  useEffect(() => {
    autoResize();
    window.addEventListener("resize", autoResize);

    // Return a function to disconnect the event listener
    return () => window.removeEventListener("resize", autoResize);
  }, []);

  const imageClicked = (event) => {
    switch (event.name) {
      case "feedback":
        showFeedback();
        break;
      // case "contributeknowledge" :
      //   showDocDepo();
      //   break;
      // case "sol360" :
      //   window.open(window.location.origin + '/sow', "_blank")
      //   break;
      case "helpvideos":
        // showHelpVideos();
        break;
      default:
        handleShowBooks(event.name);
        break;
    }
  };

  const imageMapperLoaded = (e) => {
    updateImageMapperLoaded();
  };

  return (
    <>
      <ImageMapper
        width={windowSizing.width}
        height={windowSizing.height}
        imgWidth={windowSizing.width}
        src={ChannelPartners}
        map={{
          name: "my-map",
          areas: areaCoords,
        }}
        onClick={(e) => imageClicked(e)}
        onLoad={(e) => imageMapperLoaded(e)}
      />
      <div className="feedback-highlight">
        
      </div>
    </>
  );
};

export default ChannelPartnersImageMapper;
