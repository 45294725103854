export function identifyFileFormat(fileName) {
    if (fileName.toLowerCase().indexOf("pdf") !== -1)
      return "fa fa-file-pdf pdf-file";
    else if (
      fileName.toLowerCase().indexOf("docx") !== -1 ||
      fileName.indexOf("doc") !== -1
    )
      return "fa fa-file-word word-file";
    else if (
      fileName.toLowerCase().indexOf("pptx") !== -1 ||
      fileName.toLowerCase().indexOf("ppt") !== -1
    )
      return "fa fa-file-powerpoint ppt-file";
    else if (
      fileName.toLowerCase().indexOf("xls") !== -1 ||
      fileName.toLowerCase().indexOf("xl") !== -1 ||
      fileName.toLowerCase().indexOf("csv") !== -1
    )
      return "fa fa-file-excel xl-file";
    else if (fileName.toLowerCase().indexOf("msg") !== -1)
      return "fa fa-envelope-square mail-file";
    else if (
      fileName.toLowerCase().indexOf("mp4") !== -1 ||
      fileName.toLowerCase().indexOf("wmv") !== -1 ||
      fileName.toLowerCase().indexOf("avi") !== -1
    )
      return "fa fa-file-video xl-file";
    else if (fileName.toLowerCase().indexOf("zip") !== -1)
      return "fas fa-file-archive file-zip";
    else if (fileName.toLowerCase().indexOf("vsd") !== -1)
      return "fas fa-file-alt file-zip";
    else return "fa fa-external-link-square-alt xl-file";
  }
  
  export function ISValidFileForPreview(fileName) {
    return (
      fileName.toLowerCase().indexOf("pdf") !== -1 ||
      fileName.toLowerCase().indexOf("docx") !== -1 ||
      fileName.toLowerCase().indexOf("doc") !== -1 ||
      fileName.toLowerCase().indexOf("pptx") !== -1 ||
      fileName.toLowerCase().indexOf("ppt") !== -1 ||
      fileName.toLowerCase().indexOf("mpp") !== -1 ||
      fileName.toLowerCase().indexOf("csv") !== -1 ||
      (fileName.toLowerCase().indexOf("vsd") !== -1 &&
        fileName.toLowerCase().indexOf("vsdm") == -1) ||
      fileName.toLowerCase().indexOf("vsdx") !== -1 ||
      // fileName.toLowerCase().indexOf("vsdm") !== -1 ||
      fileName.toLowerCase() === "xls" ||
      fileName.toLowerCase() === "xlsx"
    );
  }
  