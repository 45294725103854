import React from "react";
import { Modal } from "react-bootstrap";

const CustomDialogPopup = ({
  confirmationPopup,
  message,
  title,
  buttons,
  customCSS,
}) => {
  return (
    <>
    <div>

      <Modal
        show={confirmationPopup}
        className={customCSS ? "custom-modal-css " : " pt-3"}
        style={{
            display: 'block',
            top: '24vh',
            left: '35.5vw',
            borderRadius: '0',
            // height:'10vh',
            // width:'15vw'
          }}
        
        animation={false}
      >
        <div className="custom-modal-css modal-dialog-border">
          {title ? (
            <Modal.Header>
              <strong className={"me-auto "+(customCSS?"custom-modal-css-title ":"fs-5") }>{title}</strong>
            </Modal.Header>
          ) : null}
          {/* <div className={customCSS ? "custom-modal-body-css " : " "}> */}
            <Modal.Body style={{ opacity: 1 }}
             className={customCSS ? "custom-modal-body-css custom-padding-margin" : " "}
          >
            {message}
              <hr class={"border border-dark-subtle "+(customCSS?"custom-padding-margin ":"")}></hr>
            <div className=" d-flex justify-content-end ">
              <button
                type="button"
                  class={"btn btn-secondary btn-sm me-2 "+(customCSS?"custom-modal-button ":"")}
                onClick={buttons.cancel.callback}
              >
                {buttons.cancel.label}
              </button>
              <button
                type="button"
                  class={"btn btn-danger btn-sm me-2 border-0 " +(customCSS?"custom-modal-button ":"")}
                onClick={buttons.confirm.callback}
              >
                {buttons.confirm.label}
              </button>
            </div>
          </Modal.Body>
          {/* </div> */}
        </div>
      </Modal>
      </div>
    </>
  );
};

export default CustomDialogPopup;
