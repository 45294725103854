import React, { useState, useRef, useEffect } from "react";
import botAvatar from "../../img/botAvatar.png";
import ReactPlayer from "react-player";
import { Modal } from "react-bootstrap";

export default function ChatBotFeedback(props) {
  const [feedbackType, setFeedBackType] = useState("");
  const [showInputBox, setShowInputBox] = useState(false);
  const [badFeedbackSubmitted, setBackFeedbackSubmitted] = useState(false);
  const [showURLInput, setShowURLInput] = useState(false);
  const [showInputBoxWA, setShowInputBoxWA] = useState(false);
  const [videoPlayer, setVideoPlayer] = useState(false); 
  const [feedbackComment, setFeedbackComment] = useState("");
  const messagesEndRef = useRef(null);
  const [titles , setTitles] = useState([]);
  const [urls , setURLs] = useState([]);

  const handleClose = () => setVideoPlayer(false);
  const handleDocPreview = (docUrl) => {
    if (docUrl.indexOf("8082") > 0 || docUrl.indexOf("hpedelta") > 0 ) props.handlePreview(docUrl);
    else window.open(docUrl, "_blank");
  };

  const updateFeedback = (feedbackResponse) => {
    if (feedbackResponse === "good") {
      setFeedBackType("good");
      let feedbackPayload = {
        message_id: props.chatId,
        feedback: feedbackResponse,
        details: {
          category: "",
          description: "",
        },
      };
      props.submitFeedback(feedbackPayload);
      setBackFeedbackSubmitted(false);
    } else {
      setTimeout(()=>{
        messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
      },500)
      setFeedBackType("bad");
      setBackFeedbackSubmitted(false);
    }
  };

  const submitCorrectURLFeedback = (e) => {
    let feedbackPayload = {
      message_id: props.chatId,
      feedback: "bad",
      details: {
        category: "Wrong File",
        description: e.target.value,
      },
    };
    props.submitFeedback(feedbackPayload);
    setBackFeedbackSubmitted(true);
  };

  const submitOtherFeedback = (e) => {
    // if (e.target.value && e.keyCode === 13) {
    let feedbackPayload = {
      message_id: props.chatId,
      feedback: "bad",
      details: {
        category: "others",
        // description: e.target.value,
        description: feedbackComment,
      },
    };
    props.submitFeedback(feedbackPayload);
    setBackFeedbackSubmitted(true);
    // }
  };

  

  useEffect(()=>{
    if(props.answerArray && props.answerArray.length) {
      let titleArray = [];
      let textArray = [];
      props.answerArray.map((item) => {
        if(item.includes("href"))
        {
          let title = item.split("href")[0].replace("<b><u><a ", "")
          let url = item.split("href")[1]
          .replace('="', "")
          .split('"')[0]
          titleArray.push({
            docTitle : title,
            docURL : url
          })
        }
        else {
          if(item) {
            titleArray.push({
              docTitle : item,
              docURL : null
            })
          }
        }
      })
      setTitles(titleArray);
      console.log("titleeeeeee", titleArray)
    }
  },[props])

  return (
    <>
      <>
        <img
          src={botAvatar}
          style={{
            height: "25px",
            Width: "25px",
            marginLeft: "0px",
            borderRadius: "100%",
          }}
        />

        <div>
          <div className="rcw-response">
            <div className="rcw-message-text" style={{ width: "215px" }}>
              {/* {props.url ? <b>{props.title}</b> : props.title} */}
              
              {titles.map((item, index) => (
                <>
                  {item.docTitle &&
                    <>
                    <b className="pb-1">{item.docTitle}</b></>
                  }
                  {item.docURL && 
                  <>
                  {item.docURL && item.docURL.includes(".mp4") ? (
                <div class="testimonial-name">
                  <a onClick={() => setVideoPlayer(true)}>{item.docURL}</a>
                </div>
              ) : item.docURL && item.docURL.includes(".vsdm") ? (
                <div class="testimonial-name">
                  <a title="Preview Not Available">{item.docURL}</a>
                </div>
              ) : (
                <div class="testimonial-name">
                  <a onClick={() => handleDocPreview(item.docURL)}>{item.docURL}</a>
                </div>
              )}
                  </>
                  }
                </>
                
              ))}
              {/* {props.url && props.url.includes(".mp4") ? (
                <div class="testimonial-name">
                  <a onClick={() => setVideoPlayer(true)}>{props.url}</a>
                </div>
              ) : props.url && props.url.includes(".vsdm") ? (
                <div class="testimonial-name">
                  <a title="Preview Not Available">{props.url}</a>
                </div>
              ) : (
                <div class="testimonial-name">
                  <a onClick={() => handleDocPreview(props.url)}>{props.url}</a>
                </div>
              )} */}
              <div className="pull-right mt-2">
                <button
                  // className={
                  //   feedbackType === "good"
                  //     ? "chatbot-feedback-disabled positive-response mr-1"
                  //     : "positive-response mr-1"
                  // }
                  className="positive-response me-1"
                  onClick={() => updateFeedback("good")}
                  // disabled={feedbackType === "good"}
                  title="Good"
                >
                  <i class="fa fa-thumbs-up" />
                </button>
                <button
                  // className={
                  //   feedbackType === "bad"
                  //     ? "chatbot-feedback-disabled negative-response"
                  //     : "negative-response"
                  // }
                  className="negative-response"
                  onClick={() => updateFeedback("bad")}
                  // disabled={feedbackType === "bad"}
                  title="Bad"
                >
                  <i class="fa fa-thumbs-down"></i>
                </button>
              </div>
            </div>
          </div>

          <div className="col-12 mt-1">
            {!badFeedbackSubmitted && feedbackType && feedbackType === "bad" &&
             (
              <div className="response-main">
                <div className="chatbot-feedback-close">
                  <i
                    style={{ cursor: "pointer" }}
                    class="fa fa-times"
                    onClick={() => setFeedBackType("")}
                  ></i>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      // onClick={() => submitBadFeedback("wa")}
                      onClick={() => {
                        setShowInputBox(false);
                        setShowURLInput(true);
                        setShowInputBoxWA(false);
                      }}
                      // className="custom-btn-1 mr-1"
                      className={
                        showURLInput
                          ? "wrongFile-btn-active"
                          : "wrongFile-btn me-1"
                      }
                    >
                      Wrong File
                    </button>
                    <button
                      // onClick={() => submitBadFeedback("wd")}
                      onClick={() => {
                        setShowInputBoxWA(true);
                        setShowURLInput(false);
                        setShowInputBox(false);
                      }}
                      // className="custom-btn-2 mr-1"
                      className={
                        showInputBoxWA
                          ? "wrongAnswer-btn-active"
                          : "wrongAnswer-btn me-1"
                      }
                    >
                      Wrong Answer
                    </button>
                    <button
                      // className="custom-btn-3"
                      onClick={() => {
                        setShowInputBox(true);
                        setShowURLInput(false);
                        setShowInputBoxWA(false);
                      }}
                      className={
                        showInputBox ? "others-btn-active" : "others-btn me-1"
                      }
                    >
                      Others
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    {(showInputBox || showInputBoxWA) && (
                      <>
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          placeholder="Please enter your feedback here..."
                          value={feedbackComment}
                          onChange={(e)=>setFeedbackComment(e.target.value)}
                          // onKeyDown={(e) => submitOtherFeedback(e)}
                        />
                        <div className="row">
                          <div className="col-12 text-right mt-2">
                            <button
                              className="submit-btn"
                              onClick={(e) => {
                                submitOtherFeedback(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                    {showURLInput && (
                      <>
                        <input
                          type="text"
                          className="form-control form-control-sm mt-2"
                          placeholder="If available, please input the correct URL."
                        />
                        <div className="row">
                          <div className="col-12 text-right mt-2">
                            <button
                              className="submit-btn"
                              onClick={(e) => {
                                submitCorrectURLFeedback(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            )
            }
          </div>
          <div ref={messagesEndRef} />
        </div>
        <Modal
          show={videoPlayer}
          onHide={handleClose}
          dialogClassName="modal-90w"
          centered
          animation={false}
        >
          <Modal.Header>
            <Modal.Title>{props.title}</Modal.Title>
            <button
              type="button"
              translate="no"
              onClick={() => handleClose()}
              class="btn-close"
              data-bs-dismiss="modal"
            >
              {/* <i className="fa fa-times" style={{ fontSize: "15px" }}></i> */}
            </button>
          </Modal.Header>
          <Modal.Body>
            <ReactPlayer
              url={props.url}
              controls={true}
              width="1100"
              height="360"
            />
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
