import React, { useEffect, useRef, useState } from "react";
import ChannelPartnerDocumentModal from "./ChannelPartnerDocumentModal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ReactOwlCarousel from "react-owl-carousel";

const ChannelPartnerDocumentsCarousal = ({
  documentData,
  size,
  logDocClick,
  viewDocument,
  noOfBooks,
  show,
  handleClose,
  popupHeader,
  searchKey,
  handleSearchValueChange,
  fireSearch,
  docList,
  activePage,
  totalItemsCount,
  handlePageChange,
  resetResults,
  itemsCountPerPage,
  showMoreDocData,
  moreData,
  categoryName,
  getImage,
}) => {
  const startPosition = useRef(0);
  const [showModal, setShowModal] = useState(false);
  const [currentDocumentObject, setCurrentDocumentObject] = useState(undefined);
  const [currentGroupValueObject, setCurrentGroupValueObject] = useState("");
  const updateCarouselPosition = (object) => {
    if (object.item.index != startPosition.current)
      startPosition.current = object.item.index;
  };

  // const showImg = (groupValue) => {
  //   if (groupValue === "Greenlake") {
  //     return Greenlake;
  //   } else if (groupValue === "Accelerators") {
  //     return Accelerators;
  //   } else if (groupValue === "Reference Architectures") {
  //     return referenceArchitecture;
  //   } else if (groupValue === "Market Insights") {
  //     return MarketInsight;
  //   } else if (groupValue === "Success Story") {
  //     return SuccessStory;
  //   } else if (groupValue === "Announcements and Communications") {
  //     return AnnouncementsandCommunications;
  //   } else if (groupValue === "Customer References or Case Study") {
  //     return CustomerReferencesorCaseStudy;
  //   } else if (groupValue === "Competitive Insights") {
  //     return CompetitiveInsights;
  //   } else if (groupValue === "Best Practices") {
  //     return BestPractices;
  //   } else if (groupValue === "Process Documents") {
  //     return ProcessDocuments;
  //   }else if (groupValue === "Github") {
  //     return github;
  //   }
  // };
  console.log("documentData", documentData);

  useEffect(() => {
    console.log("currentDocumentObject", currentDocumentObject);
    if (currentDocumentObject != undefined )
      showMoreDocData(categoryName, currentDocumentObject.groupValue);
  }, [currentDocumentObject]);

  //   const handleClose =()=>{
  //     setShowModal(false)
  //   }
  return (
    <div>
      <ReactOwlCarousel
        items={noOfBooks}
        className="owl-theme"
        nav
        dotClass="custom-owl-dots"
        navContainerClass={documentData.length<=2 || noOfBooks>documentData.length ?"owl-nav custom-nav-class":"owl-nav"}
        style={{
          width: noOfBooks === 2 ? "8.8vw" : (noOfBooks>2 && categoryName==="Presentations")?"23vw":"18vw",
          position: "relative",
        }}
        startPosition={startPosition.current}
        onDragged={(object) => {
          console.log("object", object);
          updateCarouselPosition(object);
        }}
        onChanged={(object) => {
          console.log("object onchange", object);
          updateCarouselPosition(object);
        }}
      >
        {documentData.map((documentObj) => {
          return (
            <OverlayTrigger
              trigger={["hover", "focus"]}
              placement="top"
              overlay={
                <Tooltip style={{ border: "0px" }}>
                  {documentObj.groupValue}
                </Tooltip>
              }
            >
              <div
                className="item"
                onClick={() => {
                  // setGroupValue(documentObj.groupValue)
                  setCurrentDocumentObject(documentObj);
                  setCurrentGroupValueObject(documentObj.groupValue)
                  console.log("documentObj.doclist.docs", documentObj);
                }}
              >
                <div class="book-items">
                  <div class="main-book-wrap">
                    <div class="book-cover">
                      <div class="book-inside"></div>
                      <div class="book-image">
                        <img src={getImage(documentObj.groupValue)} />
                        <div class="effect"></div>
                        <div class="light"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </OverlayTrigger>
          );
        })}
      </ReactOwlCarousel>
      {show && currentDocumentObject && (
        <ChannelPartnerDocumentModal
          show={show}
          handleClose={() => {
            console.log("setCurrentDocumentObject");
            setCurrentDocumentObject(undefined);
            setCurrentGroupValueObject("")
            handleClose()
          }}
          popupHeader={currentGroupValueObject}
          searchKey={searchKey}
          handleSearchValueChange={handleSearchValueChange}
          fireSearch={fireSearch}
          docList={moreData.docs}
          logDocClick={logDocClick}
          activePage={activePage}
          totalItemsCount={totalItemsCount}
          handlePageChange={handlePageChange}
          resetResults={resetResults}
          itemsCountPerPage={itemsCountPerPage}
          categoryName={categoryName}
        />
      )}
    </div>
  );
};

export default ChannelPartnerDocumentsCarousal;
