import React, { Fragment, useContext, useEffect, useState } from "react";
import { AccordionContext, Modal, useAccordionButton } from "react-bootstrap";
import axios from "axios";
import TrackingService from "../TrackingService";
import Cookies from "js-cookie";
import ReactPlayer from "react-player";
import URLConfig from "../URLConfig";
import _ from "lodash";
import { Accordion, Card, Button, Row, Col } from "react-bootstrap";
import { CloseButton } from "react-bootstrap";

const KMNuggets = ({ showVideoPopup, hideVideoPopup }) => {
  const signal = axios.CancelToken.source();
  const [nugflixKMData, setNugflixKMData] = useState([]);
  const [showVideos, setShowVideos] = useState(false);
  const [videoURL, setVideoURL] = useState(null);
  const [fileName, setFileName] = useState(null);
  const trackingService = new TrackingService();
  const handleClose = () => setShowVideos(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    const isCurrentEventKey = activeEventKey === eventKey;
    return (
      <div role="button" onClick={decoratedOnClick}>
        {children}
      </div>
    );
  }

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const handleShow = (file, url) => {
    setShowVideos(true);
    console.log(file, "file");
    trackingService.LogKNVideolinkClick(Cookies.get("empnumber"), file, true);
    setVideoURL(url);
    setFileName(file);
  };

  const getNugflixData = async (cancelToken) => {
    const nugflixurl = URLConfig.getNugFlixCategorisedVideoData();

    return await URLConfig.getnewsolrBaseURL(nugflixurl)
    // await axios.get(nugflixurl, { cancelToken });
  };

  useEffect(() => {
    Promise.all([getNugflixData(signal.token)]).then((results) => {
      const nugflix = results[0];
      setNugflixKMData(nugflix.data.grouped.document_type.groups);
    });
  }, []);

  return (
    <>
      <Modal
        show={showVideoPopup}
        size="lg"
        centered
        dialogClassName="deltaHelpVideosModal"
        animation={false}
        // onHide={() => this.setState({ showVideo: false })}
      >
        {/* <Modal.Title>Help Videos</Modal.Title> */}
        <Modal.Header>
          <Row
            className="justify-content-end"
            style={{ position: "absolute", marginTop: "-0.7rem" }}
          >
            <div className="homepage-book-modal_close">
              <CloseButton
                style={{
                  float: "right",
                  fontSize: "1.2rem",
                  opacity: 0.8,
                  position: "relative",
                  right: "3px",
                }}
                onClick={() => hideVideoPopup()}
              />
            </div>
          </Row>
        </Modal.Header>
        <Modal.Body className="delta">
          <Row className="homepage-helpvideo-row">
            <Col className="p-5">
              <div className="pb-3 fw-bold homepage-helpvideos-helptext ">
                Help Videos
              </div>
              <Accordion id="KMVideoAcc">
                {nugflixKMData.map((category, index) => (
                  <Card key={`nugflixKMData${index}`} className="mb-2">
                    <Card.Header
                      style={{
                        border: "0px ",
                        backgroundColor: "transparent",
                        textDecoration: "none",
                      }}
                    >
                      <ContextAwareToggle eventKey={index.toString()}>
                        <div
                          className="help-video-accordian"
                          onClick={() => handleToggle(index)}
                        >
                          <i
                            className={`fa ${
                              expandedIndex === index ? "fa-minus" : "fa-plus"
                            }`}
                            aria-hidden="true"
                            style={{ marginRight: "15px", color:"black" }}
                          ></i>{" "}
                          {category.groupValue == "User Roles" ||
                          category.groupValue == "Key Features"
                            ? `Knowledge Management Center ${category.groupValue}`
                            : category.groupValue}
                        </div>
                      </ContextAwareToggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body
                        style={{
                          maxHeight: "250px",
                          overflowY: "auto",
                          overflowX: "hidden",
                          marginLeft: "10px",
                        }}
                      >
                        {/* Introduction to Solution 360 contains old Delta home page */}
                        {category.doclist.docs
                          .filter(
                            (item) =>
                              item.file != "Introduction to Solution 360"
                          )
                          .map((value, innerIndex) => (
                            <div key={value.id} className="col-12 row p-0 mt-2">
                              <div className="col-1">
                                <a className="fa fa-file-video xl-file"></a>
                              </div>
                              <div className="col-10">
                                <a
                                  className="breakall_word fileurl pointer"
                                  onClick={() =>
                                    handleShow(value.file, value.url)
                                  }
                                >
                                  {value.file}
                                </a>
                                <p align="left" className="pointer mb-0">
                                  {value.description}
                                </p>
                              </div>
                            </div>
                          ))}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            </Col>
            <Col className="p-5">
              {showVideos && videoURL ? (
                <div className="homepage-helpvideos-helptext">
                  {fileName}
                  <ReactPlayer
                    url={videoURL}
                    controls={true}
                    width="100%"
                    height="300%"
                  />
                </div>
              ) : (
                <div className="homepage-helpvideos-helptext">
                  Learn more about the application! <br />
                  Click <i className="fas fa-hand-point-left"></i> on any of the
                  available links to view the video.
                </div>
              )}
            </Col>
          </Row>
          {/* <KMNuggets /> */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default KMNuggets;
