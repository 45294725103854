import Cookies from "js-cookie";
import React, { useState, useEffect } from "react";
import URLConfig from "../../URLConfig.js";
import { OverlayTrigger, Tooltip, Row, Col } from "react-bootstrap";
import axios from "axios";
import { FeedbackDetails } from "./FeedbackDetails.js";
import { CloseButton } from "react-bootstrap";
import logo from "../../img/loading-icon-animated.gif"
import notepad from "../../img/NewHome/ModalBg/notepad-bg1.png"


const Feedback = ({ onFeedbackFormClose , closeFeedback }) => {
  const [feedbackType, setFeedackType] = useState(null);
  const [feedbackCategory, setFeedackCategory] = useState(null);
  const [feedbackContent, setFeedackContent] = useState(null);
  const [feedbackSummary, setFeedackSummary] = useState(null);
  const [files, setFiles] = useState([]);
  const [feedbackTypeErrorMsg, setFeedbackTypeErrorMsg] = useState(false);
  const [feedbackCategoryErrorMsg, setFeedbackCategoryErrorMsg] =  useState(false);
  const [contentErrorMessage, setContentErrorMessage] = useState(false);
  const [summaryErrorMessage, setSummaryErrorMessage] = useState(false);
  const [feedbackContentErrorMsg, setFeedbackContentErrorMsg] = useState(false);
  const [feedbackSummaryErrorMsg, setFeedbackSummaryErrorMsg] = useState(false);
  const [leftContent, setLeftContent] = useState(8000);
  const [leftSummary, setLeftSummary] = useState(8000);
  const [showCharacterLimitTitle, setShowCharacterLimitTitle] = useState(false);
  const [showCharacterLimitDetails, setShowCharacterLimitDetails] = useState(false);
  const [showFileSizeExceedError, setShowFileSizeExceedError] = useState(false);
  const [showFileExtensionError, setShowFileExtensionError] = useState(false);
  const [showSubmissionFailed, setShowSubmissionFailed] = useState(false);
  const [attachmentMandatory, setAttachmentMandatory] = useState(false);
  const [placeholderValue, setPlaceholderValue] = useState("Feedback Type");
  const [placeholderValue2, setPlaceholderValue2] = useState(
    "Please select the Category"
  );
  const [feedbackTypes, setFeedbackTypes] = useState([]);
  const [feedbackCategories, setFeedbackCategories] = useState([]);
  const [feedbackTypeName, setFeedbackTypeName] = useState(null);
  const [clicked, setClicked] = useState(false);
  const employeenumber= Cookies.get("empnumber");
  const mail = Cookies.get("mail");
  const signal = axios.CancelToken.source();
  const [showForm, setShowForm] = useState(false);
  const [filelist, setFilelist] = useState([]);

  useEffect(()=>{
    
const img = new Image()
img.onload=()=>{
  setShowForm(true)
}

img.src =notepad

  },[])
  useEffect(() => {
    getFeedbackTypeData();
    return () => {
      signal.cancel("Request Cancelled");
    };
  }, []);
  useEffect(() => {
    
    getFeedbackCategory();
    
  }, []);
  const getFeedbackTypeData = async (cancelToken) => {
    const url = URLConfig.getFeedbackTypesURL();
    try {
      const { data } = await axios.get(url, { cancelToken });
      setFeedbackTypes(data);

    } catch (error) {
      console.log("API Error", error);
    }
  };
  const getFeedbackCategory = async (cancelToken) => {
    const url = URLConfig.getFeedbackCategoryURL();
    try {
      const { data } = await axios.get(url, { cancelToken });
      setFeedbackCategories(data);
    } catch (error) {
      console.log("API Error", error);
    }
  };
  const feedbackSubmit = (e) => {
    var error = 0;
    setClicked(true);
    e.preventDefault();


    if (feedbackType === null) {
      setFeedbackTypeErrorMsg(true);
      setClicked(false);
      error = 1;
    }

    if (feedbackCategory === null) {
      setFeedbackCategoryErrorMsg(true);
      setClicked(false);
      error = 1;
    }
  /*   if (feedbackContent === null) {
      setFeedbackContentErrorMsg(true);
      setClicked(false);
      error = 1;
    } */
    if (feedbackSummary === null) {
      setFeedbackSummaryErrorMsg(true);
      setClicked(false);
      error = 1;
    }
    if (!error&&
      !feedbackTypeErrorMsg &&
      !feedbackCategoryErrorMsg &&
      !feedbackSummaryErrorMsg &&
      !showFileExtensionError &&
      !showFileSizeExceedError
    ) {
      console.log(feedbackTypeErrorMsg);
      console.log("success");
      if (feedbackTypeName?.toLowerCase() === "defect") {
        if (filelist && filelist.length > 0) {
          setAttachmentMandatory(false);
          const url = URLConfig.UploadFeedbackAttachment(mail);
          const formData = new FormData();
          if (filelist && filelist.length > 0) {
            for (let i = 0; i < filelist.length; i++) {
              formData.append("files", filelist[i]);
            }
            const config = {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            };
            axios.post(url, formData).then((res) => {
              
              if (res) {
                console.log(res.data);
                SaveFeedbackDetails(res.data);
              }
            });
          } else {
            SaveFeedbackDetails();
          }
        } else {
          setAttachmentMandatory(true);
          setClicked(false);
        }
      } else {
        const url = URLConfig.UploadFeedbackAttachment(mail);
        const formData = new FormData();
        if (filelist && filelist.length > 0) {
          for (let i = 0; i < filelist.length; i++) {
            formData.append("files", filelist[i]);
          }
          const config = {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          };
          axios.post(url, formData).then((res) => {
            
            
            console.log(res.data);
            if (res) {
              console.log(res.data);
              SaveFeedbackDetails(res.data);
            }
          });
        } else {
          SaveFeedbackDetails();
        }
      }
    } else {
      setShowSubmissionFailed(true);
    }
  };
  const SaveFeedbackDetails = (AttachmentId) => {
    console.log(feedbackTypeName,"FeedbackTypeName");
    let PostRequests = [];
    let PostRequests1 = [];
    let fileList = [];
    let AttachmentIds = [];
    var feedbackDetails = new FeedbackDetails();
    feedbackDetails.FeedbackId = "";
    feedbackDetails.Type = feedbackType;
    feedbackDetails.Types = {
      Id: feedbackType,
      FeedbackType: feedbackTypeName,
    };
    feedbackDetails.Category = feedbackCategory;
    feedbackDetails.Summary = feedbackSummary;
    feedbackDetails.Description = feedbackContent;
    // feedbackDetails.Attachment = files.length > 0 ? files[0].name : "";
    // feedbackDetails.Attachment = "Doc1.docx,Doc2.docx";
    const formData = new FormData();
    let fileAttachments = "";

    if (filelist && filelist.length > 0) {
      for (let i = 0; i < filelist.length; i++) {
        fileList.push(filelist[i].name);
        formData.append("files", filelist[i])
      }
      let fileAtt = AttachmentId.map((item) => {
        let name = item.url.split('/')
        name = name[name.length-1]
        return name
      })  
      fileAttachments = fileAtt.toString();

      feedbackDetails.Attachment = fileAttachments;
      feedbackDetails.AttachmentIds = AttachmentId.map(item => item.id).toString();
    }
    feedbackDetails.UserId = Cookies.get("empnumber");
    feedbackDetails.UserName = Cookies.get("name");
    feedbackDetails.UserEmail = Cookies.get("mail");
    console.log(feedbackDetails);
    const url = URLConfig.AddFeedbackDetails();
    const attachmenturl = URLConfig.AddFeedbackAttachment();
    PostRequests.push(axios.post(url, feedbackDetails));

    axios
      .all(PostRequests)
      .then(
        axios.spread((...responses) => {
          const response = responses;
          if (filelist && filelist.length > 0 && response[0].data !="") {
          formData.append("jiraId", response[0].data)
    PostRequests1.push(axios.post(attachmenturl, formData));
          axios
      .all(PostRequests1)
      .then(
        axios.spread((...responses) => {
          const response = responses;          
          if (response[0].data === true) {
            onFeedbackFormClose();
            setClicked(false);
          }
        })
      )
      .catch((errors) => {
        console.log(errors);
        setClicked(false);
      })
    }
    else
    { 
      setClicked(false);
        onFeedbackFormClose();
    }
        })
      )
      .catch((errors) => {
        setClicked(false);
        onFeedbackFormClose();
        console.log(errors);
      });
  };
  const handleFeedbackType = (e) => {
    console.log(e);
    var feedbacktype = e.target.value;
    if (feedbacktype === null || feedbacktype.length == 0) {
      setFeedbackTypeErrorMsg(true);
    } else {
      setFeedbackTypeErrorMsg(false);
      setFeedackType(e.target.value);
      const Feedbacktype=e.target.options[e.target.selectedIndex]?.
      text;
      console.log(Feedbacktype);
      setFeedbackTypeName(Feedbacktype);
      console.log(feedbackTypeName);
    }
  };
  const handleFeedbackCategory = (e) => {
    var feedbackcategory = e.target.value;
    if (feedbackcategory === null || feedbackcategory.length == 0) {
      setFeedbackCategoryErrorMsg(true);
    } else {
      setFeedbackCategoryErrorMsg(false);
      setFeedackCategory(e.target.value);
    }
  };
  const handleFeedbackSummary = (e) => {
    let summary = e.target.value;
    if (summary === null || summary.length == 0) {
      setLeftSummary(255);
      setFeedbackSummaryErrorMsg(true);
      setSummaryErrorMessage(false);
    } else {
      let summaryLength = summary.length;
      setShowCharacterLimitTitle(true);
      let leftSummary = 255 - summaryLength;
      leftSummary >= 0 ? setLeftSummary(leftSummary) : setLeftSummary(0);
      if (summaryLength < 255) {
        setFeedbackSummaryErrorMsg(false);
        setSummaryErrorMessage(false);
        setFeedackSummary(e.target.value);
      } else {
        setSummaryErrorMessage(true);
        setFeedbackSummaryErrorMsg(false);
      }
    }
  };
  const handleFeedbackContent = (e) => {
    let content = e.target.value;
    if (content === null || content.length == 0) {
      setLeftContent(8000);
      setFeedbackContentErrorMsg(true);
      setContentErrorMessage(false);
    } else {
      let contentLength = content.length;
      setShowCharacterLimitDetails(true);
      let leftContent = 8000 - contentLength;
      leftContent >= 0 ? setLeftContent(leftContent) : setLeftContent(0);
      if (contentLength < 8000) {
        setFeedbackContentErrorMsg(false);
        setContentErrorMessage(false);
        setFeedackContent(e.target.value);
      } else {
        setContentErrorMessage(true);
        setFeedbackContentErrorMsg(false);
      }
    }
  };
  const handleFeedbackAttachment = (e) => {
    debugger;
    const fi = document.getElementById("files");
    setShowFileExtensionError(false);
    setShowFileSizeExceedError(false);
    setShowSubmissionFailed(false);
    if (fi.files.length > 0 && fi.files.length < 4 ) {
      for (var i = 0; i < fi.files.length; ++i) {
        var file1 = fi.files.item(i).name;
        if (file1) {
          var file_size = fi.files.item(i).size;
          file_size = Math.round(file_size / 1024);
          if (file_size < 4096) {
            var ext = file1.split(".").pop().toLowerCase();
            if (
              ext.indexOf("jpg") == -1 &&
              ext.indexOf("jpeg") == -1 &&
              ext.indexOf("png") == -1 &&
              ext.indexOf("gif") == -1 &&
              ext.indexOf("bmp") == -1 &&
              ext.indexOf("docx") == -1 &&
              ext.indexOf("pdf") == -1 &&
              ext.indexOf("pptx") == -1
            ) {
              setShowFileExtensionError(true);
              setShowFileSizeExceedError(false);
              //setFiles(e.target.files);
              return false;
            } else {
              setShowFileExtensionError(false);
              setShowFileSizeExceedError(false);
              setFiles(e.target.files);
              setAttachmentMandatory(false);
              const newFile = Array.from(e.target.files);               
              if (newFile) {
                setFilelist((prevFiles) => {
                  const totalFiles = prevFiles.length + newFile.length;
                  if (totalFiles > 3) {                   
                    return prevFiles; 
                  }
                  const uniqueFiles = new Map(prevFiles.map((file) => [file.name + file.lastModified, file]));
                  newFile.forEach((file) => {
                    if (file.size <= 4 * 1024 * 1024) {
                      const key = file.name + file.lastModified;
                      if (!uniqueFiles.has(key)) {
                        uniqueFiles.set(key, file);
                      }
                    }
                  });
  
                  return Array.from(uniqueFiles.values());
                }); 
              }

            }
          } else {
            setShowFileSizeExceedError(true);
            //setFiles(e.target.files);
            setAttachmentMandatory(false);
            var ext = file1.split(".").pop().toLowerCase();
            if (
              ext.indexOf("jpg") == -1 &&
              ext.indexOf("jpeg") == -1 &&
              ext.indexOf("png") == -1 &&
              ext.indexOf("gif") == -1 &&
              ext.indexOf("bmp") == -1 &&
              ext.indexOf("docx") == -1 &&
              ext.indexOf("pdf") == -1 &&
              ext.indexOf("pptx") == -1
            ) {
              setShowFileExtensionError(true);
              return false;
            } else {
              setShowFileExtensionError(false);
              setAttachmentMandatory(false);
            }
            return false;
          }
          
        }
      }      
    } else {
      if(filelist.length == 0){
        setFilelist([]);
      }    
      setFiles([]);  
      document.getElementById("files").value = "";
      setShowFileSizeExceedError(false);
      setShowFileExtensionError(false);
    }
    document.getElementById("files").value = "";  
  };
  const feedbackReset = () => {
    setFeedackType(null);
    setFeedackCategory(null);
    setFeedackContent(null);
    setFiles([]);
    setFeedbackTypeErrorMsg(false);
    setFeedbackCategoryErrorMsg(false);
    setFeedbackContentErrorMsg(false);
    setFeedbackSummaryErrorMsg(false);
    setLeftContent(8000);
    setShowCharacterLimitDetails(false);
    setShowCharacterLimitTitle(false);
    setShowFileSizeExceedError(false);
    setShowFileExtensionError(false);
    setShowSubmissionFailed(false);
    setAttachmentMandatory(false);
    setContentErrorMessage(false);
    setSummaryErrorMessage(false);
    setFilelist([]);
  };
  const isUnsupportedFile = (file) => {
    const supportedFormats = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'pdf', 'docx', 'pptx'];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    return !supportedFormats.includes(fileExtension);
  };
  const removeAttachment = (index) => {
    const removedFile = filelist[index];
      const updatedList = filelist.filter((_, i) => i !== index);
        setFilelist(updatedList);
        if (isUnsupportedFile(removedFile)) {
          setShowFileExtensionError(false);
        }
        setShowFileSizeExceedError(false);
  };

  console.log(feedbackCategories);
  console.log(feedbackContent);
 
  return (
    <>
    {
      showForm?(
    <div id="feedbackcontainer" align="center">
      {clicked &&(
       <div className="text-center">
              <img className="loading-feedback" src={logo} alt="loading"></img>
            </div>
      )}
      <div className="col-12 margin-padding-zero" id="feedbackcontainer">
        <form
    name="feedbackform"
    id="feedbackform"
    className="feedback"
    encType="multipart/form-data"
  >
    <div className="feedback-form-top-half">
    <div className="row margin-padding-zero sow-feedback-row col-12">
            <div className="col-11 margin-padding-zero">
              <div className="input-group">
              <select
                                className="form-control form-control-sm custom-feedback-field feedback_form_control"
                                onChange={handleFeedbackType}
                                defaultValue={placeholderValue}
                              >
                                <option value="Feedback Type" disabled selected hidden>
                                Please select the Feedback Type
                                </option>
                                {feedbackTypes.map((value, index) => (
                                  <option value={value.id}>{value.feedbackType}</option>
                                ))}
                              </select>
              </div>
            </div>
            <div className="col-1 margin-padding-zero d-flex justify-content-center align-items-center">
              <span className="red">*</span>
              <span className="black">
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="feedbacktooltip" className="feedbacktooltip">
                      <table>
                        <tr className="pb-1">
                          <td>Defect</td>
                          <td>:</td>
                          <td className="ps-1">
                            When the functionality is not working as per the
                            designed feature.
                          </td>
                        </tr>
                        <tr className="pb-1">
                          <td>Enhancement</td>

                          <td>:</td>
                          <td className="ps-1">
                            New feature request that is not part of existing
                            functionality or logic.
                          </td>
                        </tr>
                        <tr className="pb-1">
                          <td>Change Request</td>
                          <td>:</td>
                          <td className="ps-1">
                            Any modification to the existing functionality.
                          </td>
                        </tr>
                        <tr className="pb-1">
                          <td>Inquiry/Feedback</td>
                          <td>:</td>
                          <td className="ps-1">
                            Any general inquiry or a positive feedback
                          </td>
                        </tr>
                      </table>
                    </Tooltip>
                  }
                >
                  <i className="fas fa-info-circle helptext2 ms-0 ps-0 pt-0" />
                </OverlayTrigger>
              </span>
            </div>

            {feedbackTypeErrorMsg && (
              <div className="errorMsg pe-3 ps-3">
                Select an appropriate feedback type
              </div>
            )}
          </div>

          <div className="row col-12 margin-padding-zero sow-feedback-row">
            <div className="col-11 margin-padding-zero">
              <select
                className="form-control form-control-sm custom-feedback-field feedback_form_control"
                onChange={handleFeedbackCategory}
                defaultValue={placeholderValue2}
              >
                <option
                  value="Please select the Category"
                  disabled
                  selected
                  hidden
                >
                  Please select the Category
                </option>
                {feedbackCategories.map((value, index) => (
                  <option value={value.id}>{value.category}</option>
                ))}
              </select>
            </div>
            <div className="col-1 d-flex margin-padding-zero d-flex justify-content-center align-items-center red"> *</div>
            {feedbackCategoryErrorMsg && (
              <div className="errorMsg pe-3 ps-3">
                Select an appropriate feedback category
              </div>
            )}
          </div>
          <div className="row col-12 margin-padding-zero sow-feedback-row">
            <div className="col-11 margin-padding-zero">
              <textarea
              style={{height:"auto"}}
                maxLength="255"
                className="form-control custom-feedback-field"
                id="typefeedback"
                rows="2"
                placeholder="Your feedback title here..."
                onChange={handleFeedbackSummary}
              ></textarea>
            </div>
            <div className="col-1 d-flex red margin-padding-zero justify-content-center align-items-center">
              *{/* Image attachment */}
              {/* ends image */}
            </div>
            {showCharacterLimitTitle && (
              <div className="pe-3 ps-3 infoMsg" align="right">
                {leftSummary} characters left
              </div>
            )}

            {feedbackSummaryErrorMsg && (
              <div className="errorMsg pe-3 ps-3">
                Please provide Feedback Title
              </div>
            )}
            {summaryErrorMessage && (
              <div className="errorMsg pe-3 ps-3">
                You have reached your maximum limit of characters allowed
              </div>
            )}
          </div>

          <div className="row col-12 margin-padding-zero sow-feedback-row">
            <div className="col-11 margin-padding-zero">
              <textarea
                style={{height: "13vh"}}
                maxLength="8000"
                className="form-control custom-feedback-field"
                id="typefeedback"
                rows="4"
                placeholder="Your detailed feedback here..."
                onChange={handleFeedbackContent}
              ></textarea>
            </div>
          {/*   <div className="col-1 ps-0 red">* */}
              {/* Image attachment */}
              {/* ends image */}
            {/* </div> */}
            {showCharacterLimitDetails && (
              <div className="pe-3 ps-3 infoMsg" align="right">
                {leftContent} characters left
              </div>
            )}

            {feedbackContentErrorMsg && (
              <div className="errorMsg pe-3 ps-3">
                Please leave your comments
              </div>
            )}
            {contentErrorMessage && (
              <div className="errorMsg pe-3 ps-3">
                You have reached your maximum limit of characters allowed
              </div>
            )}
          </div>
    </div>   
  </form>


       
  <div className="row  col-12 margin-padding-zero sow-feedback-row  feedback-form-bottom-half">
  <div className="d-flex flex-row col-auto text-left margin-padding-zero align-items-center">
  <i
                className="fas fa-paperclip"
                style={{
                  marginRight: "3%",
                  marginLeft: "3%",
                  fontSize: "2.5vh",
                }}
              ></i>
                  <input
                size="sm"
                type="file"
                className="form-control-file custom-feedback-attachment border-0"
                id="files"
                onChange={(e) => handleFeedbackAttachment(e)}
                multiple
                style={{ marginRight: "1.2vw", fontSize: "2.1vh",width:"5.9vw" }}
              />        
                  {/* {files.length > 0 && (
                    <span
                      className="red ps-0 pointer"
                      onClick={removeAttachment}
                      title="Remove the attachment"
                      translate="no"
                      id="remove_file_icon"
                      style={{marginLeft:'-110px'}}
                    >
                      <b> X</b>
                    </span>
                  )} */}
                {/* </div> */}
              </div>
            {/* {files.length > 0 && (
              <span
                className="red pr-0 pointer"
                onClick={removeAttachment}
                title="Remove the attachment"
              >
                <b> X</b>
              </span>
            )} */}
             {filelist.length > 0 &&  <div
              className="mt-1"
              style={{ width: "50vw", marginLeft: "1.3vw", marginTop: "0" }}
            >
              {filelist.length > 0 && (
                <ul
                  className="list-unstyled"
                  style={{ padding: 0, margin: 0, listStyleType: "none" }}
                >
                  {filelist.map((file, index) => (
                    <li
                      key={index}
                      style={{
                        marginBottom: "5px",
                        fontSize: "2vh",
                        textAlign: "left",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>{file.name}</span>
                      <span
                        className="red pr-0 pointer"
                        onClick={() => removeAttachment(index)}
                        title="Remove the attachment"
                        style={{
                          cursor: "pointer",
                          color: "red",
                          marginLeft: "2vw",
                          fontSize: "3vh",
                        }}
                      >
                        <b>X</b>
                      </span>
                    </li>
                  ))}
                </ul>
              )}
            </div>}
            {feedbackType === "Defect" && (
              <div className="col-4 text-left pb-2 ps-0 red">*</div>
            )}
            {showFileSizeExceedError && (
              <div className=" row errorMsg pe-3 ps-4 col-12">
                Please select a file less than 4MB
              </div>
            )}
            {showFileExtensionError && (
              <div className="row errorMsg pe-3 ps-4 col-12">
                Unsupported file format (Please upload files with extensions
                docx,pdf,png,jpg,jpeg,gif,bmp)
              </div>
            )}
            {attachmentMandatory && (
              <div className="row errorMsg pe-3 ps-3">
                Please attach the screenshot. Mandatory for Defect.
              </div>
            )}
            <div className="pe-3 ps-4 mt-1 custom-feedbackNote row col-12">
              <table>
                <tr>
                  <td>
                    <p className="fontx12">
                      <u>Note</u>: Please furnish all the required details above
                      (For ex: Description including snapshot with timestamp &
                      keyword (if any))
                      <br />
                      <span className="ps-4 ms-1">
                        File should not be larger than 4 MB. Only Image
                        files,.docx,.pptx and .pdf files are allowed.
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>
            {showSubmissionFailed && (
              <div className=" row errorMsg pe-3 ps-3">
                Could not submit your feedback. Please check if you have met the
                conditions prior to submitting the form
              </div>
            )}

<div className="row col-12 margin-padding-zero">
              <Row className="margin-padding-zero">
                <Col className="d-flex justify-content-start margin-padding-zero">
                  <button
                    className="btn btn-success btn-sm custom-sowfeedbackBtn sow-feed-back-btn btn-properties"
                    onClick={(e) => feedbackSubmit(e)}
                  >
                    Submit
                  </button>
                  <input
                    type="reset"
                    className="btn btn-warning btn-sm custom-feedbackBtn sow-feed-back-btn btn-properties"
                    value="Reset"
                    onClick={feedbackReset}
                  />
                </Col>
              </Row>
              {/* <div className="col-9 ms-2" style={{border:"1px solid blue"}} ></div> */}
            </div>
            {/* <div className="row  col-12 mt-2">
              <div class="col-5">
                <button
                  class="btn btn-success btn-sm custom-feedbackBtn me-1"
                  onClick={(e) => feedbackSubmit(e)} disabled ={clicked}
                >
                  Submit
                </button>
                <input
                  type="reset"
                  class="btn btn-warning btn-sm custom-feedbackBtn"
                  value="Reset"
                  onClick={feedbackReset}
                />
              </div>
            </div> */}
          </div>
      </div>
    </div>
          ):null}
          </>
  );
};
export default Feedback;