import axios from "axios";
import { error } from "jquery";
import { useState } from "react";
import { Col, Form, Row, Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { AsyncPaginate } from "react-select-async-paginate";
import { toast } from "react-toastify";
import URLConfig from "../../../../URLConfig";

const SendNotificationModal = ({ show, handleCloseSendNotificationModal }) => {
  const [NotificationObj, setNotificationObj] = useState({
    title: "",
    body: "",
    url: "",
    users: [],
    notifyAllUsers: false,
  });
  const [errorMessageObj, setErrorMessageObj] = useState({
    title: "This is required.",
    body: "This is required.",
    url: " ",
    users: "This is required.",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [Allusers, setAllusers] = useState([]);
  const [selectedusers, setSelectedusers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const handlSendNotification = async () => {
    try {
      setIsLoading(true);
      const URL = URLConfig.SendNotificationToken();
      const payload = {
        ...NotificationObj,
        users: NotificationObj.users.map((element) => {
          return element.value;
        }),
        url: NotificationObj.url ? NotificationObj.url : process.env.REACT_APP_ENV ==="PROD"? "https://cp.hpedelta.com/":"https://cp-stage.hpedelta.com/",
        notify_all_users: NotificationObj.notifyAllUsers,
      };
      delete payload.notifyAllUsers;

      var result = await axios.post(URL, payload);

      setIsLoading(false);
      if (result.status == 200) {
        toast.success("Notification sent successfully!");
      } else {
        toast.success("Something went wrong!");
      }
    } catch {
      toast.success("Something went wrong!");
      console.log("Error Occured in handlSendNotification()", error);
    }
    handleCloseSendNotificationModal();
    setIsLoading(false);
  };

  const defaultAdditional = {
    page: 1,
  };

  const loadOptions = async (search, page) => {
    const URL = URLConfig.GetUsers();

    let pageNumber =
      search.toLowerCase() !== searchValue.toLowerCase() ? 1 : currentPage;
    let result = await axios.get(URL, {
      params: {
        searchText: search.toLowerCase(),
        pageNumber: pageNumber,
        pageSize: 1000,
      },
    });

    setCurrentPage(
      search.toLowerCase() !== searchValue.toLowerCase() ? 1 : currentPage + 1
    );
    setSearchValue(search.toLowerCase());

    let finalResult = result.data.map((obj) => {
      return {
        value: obj.emailId,
        label: obj.username,
      };
    });

    return {
      options: finalResult,
      hasMore: finalResult.length >= 1000 ? true : false,
    };
  };

  const loadPageOptions = async (q, prevOptions, { page }) => {
    const { options, hasMore } = await loadOptions(q, page);

    return {
      options,
      hasMore,

      additional: {
        page: page + 1,
      },
    };
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseSendNotificationModal}
        backdrop={"static"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "395px", overflowY: "auto" }}>
          <Form.Group
            as={Row}
            className={errorMessageObj.title ? "" : "mb-3"}
            controlId="employeeNumber"
          >
            <Form.Label column sm="2">
              Title
            </Form.Label>
            <Col sm="10">
              <Form.Control
                type="text"
                //   disabled={true}
                placeholder="Enter Notification Title"
                className="col col-9"
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessageObj((prevErrorMessage) => {
                      return {
                        ...prevErrorMessage,
                        title: "This is required.",
                      };
                    });
                  } else {
                    setErrorMessageObj((prevErrorMessage) => {
                      return { ...prevErrorMessage, title: "" };
                    });
                  }
                  setNotificationObj((prevState) => {
                    return { ...prevState, title: e.target.value };
                  });
                }}
              />
              {errorMessageObj.title && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errorMessageObj.title}
                </p>
              )}
            </Col>
            {/* {errors.employeeNumber && (
                  <p style={{ color: "red" }}>this is required</p>
                )} */}
          </Form.Group>
          <Form.Group
            as={Row}
            className={errorMessageObj.title ? "mb-3" : "mb-3"}
            controlId="emailId"
          >
            <Form.Label column sm="2">
              Body
            </Form.Label>
            <Col sm="10">
              <Form.Control
                //   disabled={true}
                // type="email"
                style={{ height: "90px" }}
                as={"textarea"}
                placeholder="Enter Notification Description"
                onChange={(e) => {
                  if (!e.target.value) {
                    setErrorMessageObj((prevErrorMessage) => {
                      return { ...prevErrorMessage, body: "This is required." };
                    });
                  } else {
                    setErrorMessageObj((prevErrorMessage) => {
                      return { ...prevErrorMessage, body: "" };
                    });
                  }
                  setNotificationObj((prevState) => {
                    return { ...prevState, body: e.target.value };
                  });
                }}
              />
              {errorMessageObj.body && (
                <span style={{ color: "red", fontSize: "12px" }}>
                  {errorMessageObj.body}
                </span>
              )}
            </Col>
            {/* {errors.emailId && (
                  <p style={{ color: "red" }}>this is required</p>
                )} */}
          </Form.Group>
          <Form.Group
            as={Row}
            className={errorMessageObj.body ? "" : "mb-3"}
            controlId="employeeName"
          >
            <Form.Label column sm="2">
              URL
            </Form.Label>
            <Col sm="10">
              <Form.Control
                //   disabled={true}
                type="text"
                placeholder="Enter URL"
                //   {...register("employeeName", { required: true })}
                onChange={(e) => {
                  // if (!e.target.value) {
                  //   setErrorMessageObj((prevErrorMessage) => {
                  //     return { ...prevErrorMessage, url: "This is required." };
                  //   });
                  // } else {
                  //   setErrorMessageObj((prevErrorMessage) => {
                  //     return { ...prevErrorMessage, url: "" };
                  //   });

                  // }
                  setNotificationObj((prevState) => {
                    return { ...prevState, url: e.target.value };
                  });
                }}
              />
              {errorMessageObj.url && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errorMessageObj.url}
                </p>
              )}
            </Col>
            {/* {errors.employeeName && (
                  <p style={{ color: "red" }}>this is required</p>
                )} */}
          </Form.Group>
          <Form.Group
            as={Row}
            className={errorMessageObj.url ? "" : "mb-3"}
            controlId="Role"
          >
            <Form.Label column sm="2">
              Users
            </Form.Label>
            <Col sm="10">
              {/* <Select
                  placeholder="Enter Role"
                //   value={rolesSelected}
                //   {...register("roles", { required: true })}
                //   onChange={(option) => handleRolesSelectOnChange(option)}
                //   options={modifyRolesSelected(props.rolesOptions, false)}
                onChange={(options)=>{
                  setNotificationObj((prevSatete)=>{
                    return {...prevSatete,users:[options]}

                  })
                }}
                options={
                  Allusers}
                  isMulti={true}
                /> */}

              <AsyncPaginate
                additional={defaultAdditional}
                value={NotificationObj["users"]}
                isMulti
                loadOptions={loadPageOptions}
                onChange={(selectedUser) => {
                  if (
                    selectedUser.length === 0 &&
                    !NotificationObj.notifyAllUsers
                  ) {
                    setErrorMessageObj((prevErrorMessage) => {
                      return {
                        ...prevErrorMessage,
                        users: "This is required.",
                      };
                    });
                  } else {
                    setErrorMessageObj((prevErrorMessage) => {
                      return { ...prevErrorMessage, users: "" };
                    });
                  }
                  setNotificationObj((prevSatete) => {
                    return { ...prevSatete, users: selectedUser };
                  });
                }}
                isDisabled={NotificationObj.notifyAllUsers}
              />
              {errorMessageObj.users && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {errorMessageObj.users}
                </p>
              )}
            </Col>
            {/* {errors.roles && (
                  <p style={{ color: "red" }}>this is required</p>
                )} */}
          </Form.Group>
          <Form.Check // prettier-ignore
            type={"checkbox"}
            // id={`default-${type}`}
            label="Notify all users"
            onChange={(e) => {
              if (e.target.checked) {
                setErrorMessageObj((prevErrorMessage) => {
                  return { ...prevErrorMessage, users: "" };
                });
              }
              setNotificationObj((prevState) => {
                return { ...prevState, notifyAllUsers: e.target.checked };
              });
            }}
          />
          {/* </Row> */}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseSendNotificationModal}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={handlSendNotification}
            disabled={
              errorMessageObj.title ||
              errorMessageObj.body ||
              // errorMessageObj.url ||
              errorMessageObj.users
            }
          >
            {isLoading ? (
              <Spinner size="sm" animation="border" role="status">
                <span className="sr-only">Sending...</span>
              </Spinner>
            ) : (
              "Send"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SendNotificationModal;
