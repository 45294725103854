import URLConfig from "./URLConfig";

class TrackingService {
  constructor() {
    this.config = URLConfig;
  }

  async fireApiCall(data) {
    try {
      await fetch(this.config.getURL_UserTracking(), {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      console.log(error);
    }
  }

  async LogUserLogin(UserID) {
    const data = {
      UserId: UserID,
      type: "Login",
      data: "",
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogDocDepoClick(UserID) {
    const data = {
      UserId: UserID,
      type: "LogDocDepo_link_click",
      data: "",
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogSearch(UserID, SearchStr) {
    const data = {
      UserId: UserID,
      type: "Search",
      data: SearchStr,
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogAccountSearch(UserID, SearchStr) {
    const data = {
      UserId: UserID,
      type: "AccountSearch",
      data: SearchStr,
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogMyContributionlinkClick(UserID) {
    const data = {
      UserId: UserID,
      type: "LogMyContribution_link_click",
      data: "",
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogFeedbacklinkClick(UserID) {
    const data = {
      UserId: UserID,
      type: "LogFeedback_link_click",
      data: "",
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async LogLinksClick(UserID, displayName, Path, URL) {
    const data = {
      UserId: UserID,
      type: "LogFooterLinks_click",
      data: displayName,
      Source: URL,
      host: window.location.href,
      FileName: displayName,
    };
    this.fireApiCall(data);
  }

  async LogKNVideolinkClick(UserID, file) {
    const data = {
      UserId: UserID,
      type: "LogKNVideo_link_click",
      data: file,
      host: window.location.href,
    };
    this.fireApiCall(data);
  }

  async OpenLink(UserID, documentDetails) {
    const data = {
      UserId: UserID,
      type: "Open_Document_Link",
      data: documentDetails.url,
      host: window.location.href,
      FileName: documentDetails.file + "." + documentDetails.file_type,
      Source: documentDetails.doc_source,
    };
    this.fireApiCall(data);
  }

  async LogChatbotClick(UserID, msg) {
    const data = {
      UserId: UserID,
      type: "LogChatbot_link_click",
      data: msg,
      host: window.location.href,
    };
    this.fireApiCall(data);
  }
}
export default TrackingService;
