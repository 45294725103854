import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
    useEffect,
  } from "react";
  import { Nav, Tab, Col, Row, Tabs } from "react-bootstrap";
  import CryptoJS from "crypto-js";
  import { saveAs } from "file-saver";
  import URLConfig from "../URLConfig";
  import axios from "axios";
  import Modal from "react-bootstrap/Modal";
  import Button from "react-bootstrap/Button";
  import TrackingService from "../TrackingService";
  import Cookies from "js-cookie";
  
  function Accelerator(props) {
    const trackingService = new TrackingService();
    
    // Code for download
    const logDocClick = () => {
      trackingService.OpenLink(Cookies.get("empnumber"), props.documentDetails);
    };
  
    const handleDownload = async () => {
      const download = props.docList.filter(
        (item) => item.file == props.documentName
      );
      const URL = `https://hpedelta.com/viewer.aspx?requestType=EV`;
      const files = [];
  
      files.push(
        "F:\\AnPS\\CampaignBanner\\" +
        download[0].url
          .replace("https://hpedelta.com:8083/", "")
          .replace(/ /g, "%20")
          .split("/")
          .join("\\")
      );
  
      if (files.length > 0) {
        try {
          await axios.post(
            URL,
            { files },
            { responseType: "blob" }
          ).then((response) => {
            if (files.length > 0 && files.length == 1) {
              const pdfURL = URLConfig.ConvertDocToPdf();
              const fileName = files[0].replace(/^.*[\\\/]/, "").replace(/%20/g, " ").replace(/docx/, "pdf")
              const formData = new FormData();
              formData.append("fileName", fileName);
              formData.append("blob", response.data);
              const res = axios.post(pdfURL, formData, {
                responseType: "blob",
              }).then((result) => {
                saveAs(result.data, fileName);
              });
            }
          }).catch((ex) => {
            console.log(ex)
          });
        } catch (error) {
          console.log(error);
        }
      }
    };
  
    // const handleDownload = async () => {
    //   const download = props.docList.filter(
    //     (item) => item.file == props.documentName
    //   );
    //   // e.preventDefault();
    //   // setDownloadProgress(0);
    //   // setFileLoader(true);
    //   const URL = `https://hpedelta.com/viewer.aspx?requestType=EV`;
    //   const files = [];
  
    //   files.push(
    //     "F:\\AnPS\\Sharepointfiles\\" +
    //       download[0].url
    //         .replace("https://hpedelta.com:8082/", "")
    //         .replace(/ /g, "%20")
    //         .split("/")
    //         .join("\\")
    //   );
  
    //   if (files.length > 0) {
    //     try {
    //       const response = await axios.post(
    //         URL,
    //         { files },
    //         { responseType: "blob" }
    //       );
    //       if (files.length > 0 && files.length == 1) {
    //         const pdfURL = `https://localhost:44322/api/File/GeneratePdf`
    //         const blob = new FormData();
    //         blob.append("", files[0].replace(/%20/g, " "));
    //         const res = await axios.post(pdfURL, blob, {
    //           responseType: "blob",
    //         });
    //         if (res.data) {
    //           // const name = files[0].replace(/^.*[\\\/]/, "").replace(/%20/g, " ").replace(/docx/, "pdf")
    //           // saveAs(res.data, name);
    //           saveAs(res.data, `download.pdf`);
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error);
    //     }
    //   }
    // };
  
    const fetchDataAndDownload = async () => {
      try {
        // setLoading(true);
  
        const URL =
          URLConfig.getURLDeltaAPI() +
          "api/GreenlakeDownlaod/RPS_05_Accelerators_DBPA_Squest_16102023_v1.1.html";
        // Fetch data from the backend
        const response = await fetch(URL);
  
        // Extract filename and MIME type from Content-Disposition header
        const contentDisposition = response.headers.get("Content-Disposition");
        let fileName = "download";
        let mimeType = "application/octet-stream"; // Default MIME type
  
        if (contentDisposition) {
          const match = contentDisposition.match(/filename="?([^"]*)"?/);
          if (match) {
            fileName = match[1];
          }
        }
  
        if (response.headers.has("Content-Type")) {
          mimeType = response.headers.get("Content-Type");
        }
  
        // Fetch blob data from the backend
        const blobData = await response.blob();
  
        // Create a blob URL for the blob data
        const blobUrl = window.URL.createObjectURL(
          new Blob([blobData], { type: mimeType })
        );
  
        // Create a temporary anchor element
        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        downloadLink.setAttribute("download", fileName);
  
        // Trigger a click on the anchor to start the download
        document.body.appendChild(downloadLink);
        downloadLink.click();
  
        // Remove the temporary anchor element
        document.body.removeChild(downloadLink);
  
        // Revoke the blob URL
        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("Error fetching or downloading data:", error);
      } finally {
        // setLoading(false);
      }
    };
    const isChannelPartner = localStorage.getItem("isChannelPartner")
    return (
      <>
        {/* {props.showAccelerator && ( */}
        <Modal
          size="lg"
          animation={false}
          centered
          show={props?.showAccelerator}
          // show = {}
          // onHide={this.showDeliveryMixPdf}
          dialogClassName="delivery-mix-modal"
        // onEntered={() => {
        //   this.state.loader = true;
        // }}
        // onExited={() => {
        //   this.state.loader = false;
        // }}
        >
          <Modal.Header className="ms-2 mt-2 me-2" style={{ paddingBottom: "0px" }}>
            <Modal.Title>
              <p className="ms-4 custom-pdftitle">{props.documentName.replace(".docx", "")}</p>
            </Modal.Title>
            <button
              type="button"
              translate="no"
              onClick={() => props.change()}
              class="btn-close"
              data-bs-dismiss="modal"
            >
              {/* × */}
            </button>
          </Modal.Header>
          <Modal.Body className={isChannelPartner === "1" ? "ps-3 pe-3" : ""} style={{ height: "70vh", paddingTop: "0rem" }}>
            {(props.results.length == 0 || props.loading == true) && (
              <div className="preview_iframe">
                <iframe frameborder="0" allowfullscreen />
              </div>
            )}
            {props.results.length > 0 && (
              <Tab.Container
                defaultActiveKey={props?.results[0]?.blockName}
                style={{ border: "none" }}
              >
                <Row>
                  <Col sm={3} className="col-auto ">
                    <Nav variant="tabs" style={{ borderBottom: "none" }}>
                      {props?.results.map((tab) => (
                        <Nav.Item key={tab.blockName}>
                          <Nav.Link
                            className="custome-nav-link"
                            eventKey={tab.blockName}
                            style={{
                              width: 300,
                              border: "2px solid #01a982",
                              backgroundColor: "transparent",
                              marginLeft: "20px",
                              // marginRight: "10px",
                              marginTop: "4px",
                              font: "50px",
                              fontSize: "small",
                            }}
                          >
                            {tab.blockName}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col sm={9} className="custom-rightbar border-left">
                    <Tab.Content style={{ maxHeight: '100%', overflowY: 'auto', padding: "10px" }}>
                      {props.results.map((tab) => (
                        <Tab.Pane key={tab.blockName} eventKey={tab.blockName}>
                          <div
                            // className="p-3"
                            className="pt-0 ps-2 pe-2"
                            dangerouslySetInnerHTML={{ __html: tab.blockContent }}
                          ></div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary btn-sm custom-deliveryDownload"
            >
              <a
                // href={deliveryMixPdf}
                // onClick={
                //   fetchDataAndDownload()
                // }
                onClick={() => { handleDownload(); logDocClick() }}
                // download="Delivery Mix Percentage.pdf"
                style={{ color: "white", textDecoration: "none" }}
              >
                Download
              </a>
            </button>
            <button
              type="button"
              className="btn btn-primary btn-sm custom-feedbackClose"
              onClick={() => props.change()}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {/* )} */}
      </>
    );
  }
  
  export default Accelerator;