import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import SendNotificationModal from './SendNotificationModal';

const Sidebar = ({show,handleClose}) =>  {
  const [isSendNotificationModalOpen, setIsSendNotificationModalOpen] = useState(false);

  const handleCloseSendNotificationModal = () => setIsSendNotificationModalOpen(false);
  const handleOpenSendNotificationModal = () => setIsSendNotificationModalOpen(true);

  return (
    <>
      <Offcanvas show={show} onHide={handleClose} style={{height:"25vh"}}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title style={{fontSize:"2.5vh"}}>Admin Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
         <ul style={{listStyle:"none"}}>
            <li onClick={handleOpenSendNotificationModal}>
                <button type="button" class="btn btn-success send-notification-btn">Send Notification</button>
            </li>
         </ul>
        </Offcanvas.Body>
      </Offcanvas>

      {isSendNotificationModalOpen && <SendNotificationModal
      show={isSendNotificationModalOpen}
      handleCloseSendNotificationModal={handleCloseSendNotificationModal}
      />}
    </>
  );
}

export default Sidebar;



