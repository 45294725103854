import React, { useContext } from "react";
import { Card, AccordionContext, useAccordionButton } from "react-bootstrap";

const ChatBotAccordion = ({ children, as, eventKey, callback, message }) => {
  const currentEventKey = useContext(AccordionContext);

  const handleToggleClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <Card.Header
      as={as}
      bsPrefix="chatbot-flyer-accordian"
      onClick={handleToggleClick}
    >
      {isCurrentEventKey ? (
        <div className="more-info">
          Hide Related Documents
          <i className="fas fa-chevron-down fa-xs pe-2" />
        </div>
      ) : (
        <div className="more-info">
          {message} <i className="fas fa-chevron-right fa-xs pe-2" />
        </div>
      )}
      {children}
    </Card.Header>
  );
};

export default ChatBotAccordion;
