import React, { useEffect, useState } from "react";
import { Button, CloseButton, Col, Modal, Row } from "react-bootstrap";
import Documents from "./Document";
import Pagination from "react-js-pagination";
import bookModal from "../img/ChannelPartner/NewImg/book-bg.webp"

const ChannelPartnerDocumentModal = ({
  show,
  handleClose,
  popupHeader,
  searchKey,
  handleSearchValueChange,
  fireSearch,
  docList,
  logDocClick,
  activePage,
  totalItemsCount,
  handlePageChange,
  resetResults,
  itemsCountPerPage,
  modalBody,
  categoryName
}) => {
  const size = 8;
  const [currentDocURL, setCurrentDocURL] = useState("");
  console.log("Modal popupheader", popupHeader);
  const [showForm, setShowForm] = useState(false);

  useEffect(()=>{
    
    const img = new Image()
    img.onload=()=>{
      setShowForm(true)
    }
    
    img.src =bookModal
    
      },[])

  return (
    <>
    {
      showForm?(
    <div>
      <Modal
        show={show}
        size="lg"
        centered
        dialogClassName={
          modalBody
            ? "channel-partner-modal-table"
            : "channel-partner-modal" + " ChannelPartner-documentModal"
        }
        animation={false}
        onHide={() => handleClose()}
      >
        {/* <Modal.Title>Help Videos</Modal.Title> */}
        {modalBody ? (
          <Modal.Header className="mt-0 pb-1 pt-1" closeButton>
            <Modal.Title>{popupHeader}</Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header>
            <Row
              className="justify-content-end ChannelPartner-modal_close-row"
              // style={{ position: "absolute", marginTop: "-0.7rem" }}
            >
              <div className="ChannelPartner-modal_close">
                <CloseButton
                  style={{
                    float: "right",
                    fontSize: "1.2rem",
                    opacity: 0.8,
                    position: "relative",
                    right: "3px",
                  }}
                  onClick={() => handleClose()}
                />
              </div>
            </Row>
          </Modal.Header>
        )}
        <Modal.Body className="pb-0">
          <Row className="homepage-helpvideo-row">
            <Col className="" style={{ paddingLeft: modalBody?"2vw":"8vw",position:"relative",left:"5vw" }}>
              {modalBody ? null : (
                <div className="pb-3 fw-bold homepage-helpvideos-helptext">
                  {popupHeader}
                </div>
              )}
              {modalBody ? (
                modalBody
              ) : (
                <>
                  <div className="row col-12">
                    <div className="col-6">
                      <input
                        className="form-control form-control-sm ms-3 mb-3"
                        type="text"
                        value={searchKey}
                        onChange={handleSearchValueChange}
                        onKeyUp={(e)=>{fireSearch(e,categoryName)}}
                        placeholder="Search File Name"
                      />
                    </div>
                    <span
                      className="col-6"
                      title="Click to reset current search."
                    >
                      <i
                        className="fas fa-sync-alt reset-btn"
                        onClick={() => resetResults(categoryName)}
                      ></i>
                    </span>
                  </div>
                  <div className="documentlist-scroll col-11">
                    <Documents
                      docList={docList}
                      logDocClick={logDocClick}
                      size={size}
                      setCurrentDocURL={setCurrentDocURL}
                    />
                  </div>
                  {docList && docList.length > 0 ? (
                  <div className="text-center">
                    <Pagination
                      className=""
                      prevPageText="<"
                      nextPageText=">"
                      firstPageText="<<"
                      lastPageText=">>"
                      activePage={activePage}
                      itemsCountPerPage={itemsCountPerPage}
                      totalItemsCount={totalItemsCount}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber)=>{
                        
                        handlePageChange(pageNumber,categoryName)
                      }}
                    />
                  </div>
                  ) : (
                    <div className="text-center CP-bookshelf-NoResult">No Matching Data</div>
                  )}
                </>
              )}
            </Col>

            {modalBody ? null : (
              <Col className="p-5 document-preview-iframe-wrapper">
                <div>
                  <iframe
                    src={currentDocURL}
                    className="document-preview-iframe"
                  ></iframe>
                </div>
              </Col>
            )}
          </Row>
          {/* <KMNuggets /> */}
        </Modal.Body>
      </Modal>
    </div>
      ):null}
          </>
  );
};

export default ChannelPartnerDocumentModal;
